import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { IPropsWithChildren, IPropsWithClassName } from 'interfaces';
import { CONTAINER_WIDTH } from 'enums';
import Container from './Container';

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum BG_COLOR {
  WHITE = 'bg-white',
  LIGHT_GRAY = 'bg-warmGray-100',
  GRAY = 'bg-warmGray-300',
}

export interface SectionProps extends IPropsWithClassName, IPropsWithChildren {
  bgColor?: BG_COLOR;
  size?: CONTAINER_WIDTH;
  noPadding?: boolean;
}

const Section = ({
  className,
  children,
  size = CONTAINER_WIDTH.XL7,
  bgColor = BG_COLOR.WHITE,
  noPadding = false,
}: SectionProps) => {
  const componentClasses = classNames(
    {
      'pt-16 pb-14': !noPadding,
    },
    className,
  );

  return (
    <section className={bgColor}>
      <Container className={componentClasses} size={size}>
        { children }
      </Container>
    </section>
  );
};

Section.propTypes = {
  size: PropTypes.string,
  className: PropTypes.string,
  bgColor: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  noPadding: PropTypes.bool,
};

Section.defaultProps = {
  className: null,
  size: CONTAINER_WIDTH.XL7,
  bgColor: BG_COLOR.WHITE,
  children: null,
  noPadding: false,
};

export default Section;
