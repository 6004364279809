/* eslint-disable max-len */
import React, { useEffect } from 'react';

import Hero from 'components/hero/hero';
import Section, { BG_COLOR } from 'components/section/Section';
import SectionHeader from 'components/section/SectionHeader';
import EcosystemServices from 'components/ecosystem_service/EcosystemServices';
import Button from 'components/button/button';
import PageTitleContext from 'context/PageTitleContext';
import { CONTAINER_WIDTH } from 'enums';
import MapPlanViewWidget from 'widgets/map/MapPlanViewWidget';

import loopImg from 'images/loop.svg';

const Home = () => {
  const { setPageTitle } = React.useContext(PageTitleContext);

  useEffect(() => {
    setPageTitle('');
  }, [setPageTitle]);

  return (
    <React.Fragment>
      <Hero
        title="Intelligent Ops + Ecosystem Protection"
        lead="This initial version dashboard is part of our participatory systems design process. DG engineers integrate feedback from stakeholders with each release, informing the design of our entire tech stack, from collector vehicles to our cloud-based Adaptive Management System architecture."
        cta={{ title: 'Learn more', to: 'https://metals.co?ref=tmc', blank: true }}
        size={CONTAINER_WIDTH.XL4}
      >
        <img src={loopImg} alt="Adaptative Management System" className="mx-auto pb-6" width="415" height="294" />
      </Hero>
      <Section bgColor={BG_COLOR.LIGHT_GRAY}>
        <SectionHeader title="2024 Production Data">
          Realtime production data will enable the regulator to verify collection planning, nodule/mineral collection data,
          revenue, royalties and asset monitoring. The map shows an example of how operations would progress during
          production. Sample data is used to show how equipment locations would be updated in the dashboard in
          near-real-time.
        </SectionHeader>
        <div className="my-5 text-center">
          <Button type="link" to="/operations" title="Explore our production data" />
        </div>

        <div className="mt-8">
          <MapPlanViewWidget />
          <p className="mt-3 text-xs text-black">
            * Production paths are for visual reference only and not final collection plan.
          </p>
        </div>
      </Section>

      <Section>
        <SectionHeader title="Ecosystem Services">
          Ecosystem-Based Management is centered on sustaining and conserving the ecosystem services and functions which
          humans utilize. Since services cannot be directly measured, ecosystem indicators are used as proxy parameters
          which allow the system health to be calculated.
        </SectionHeader>

        <EcosystemServices disabledClick />
        <p className="text-xxs text-black">
          * Ecosystem services and indicator health are for visual
          reference only and not actual management plan.
        </p>
        <div className="my-5 text-center">
          <Button type="link" to="/environment" title="See all services and indicators" />
        </div>
      </Section>
    </React.Fragment>
  );
};

export default Home;
