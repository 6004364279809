import React, { useEffect, useRef, useState } from 'react';
import { renderToString } from 'react-dom/server';
import {
  Map, NavigationControl, Popup, Marker, LngLat, LngLatBoundsLike, MapboxEvent, ScaleControl,
} from 'mapbox-gl';
import '../../../node_modules/mapbox-gl/dist/mapbox-gl.css';
import { MAPBOX_ACCESS_KEY } from '../../config';
import { DEFAULT_CENTER, Styles } from './Mapbox';
import PopupContent from './PopupContent';
import Modal from '../modal/Modal';

import O from './layers/O';
import SM from './layers/SM';
import MB from './layers/MB';
import MP from './layers/MP';
import BC from './layers/BC';

import './map.scss';

interface MapOverlayProps {
  url: string,
  coordinates: number[][]
}

interface MapPlanViewProps {
  endpointBoxCores: string,
  endpointSeaMounts: string,
  endpointMineBox: string,
  endpointMinePath: string,
  backgroundSource: MapOverlayProps | null,
  mineBlockBounds: LngLatBoundsLike,
  onDrag(e: any): void,
}

const MapPlanView = (props: MapPlanViewProps) => {
  const triggerId = 'mineSiteTrigger';
  const {
    endpointBoxCores,
    endpointSeaMounts,
    endpointMineBox,
    endpointMinePath,
    backgroundSource,
    mineBlockBounds,
    onDrag,
  } = props;

  const defaultCenter = new LngLat(DEFAULT_CENTER[0], DEFAULT_CENTER[1]);
  const mapContainer = useRef<HTMLElement>();
  const [mapObject, setMapObject] = useState<Map | null>(null);
  const [showLayers, setShowLayers] = useState(false);

  const panToMineBlock = (map: Map, popup?: Popup | null, marker?: Marker | null) => {
    map.panTo(defaultCenter);
    map.flyTo({
      center: defaultCenter,
      zoom: 10,
      duration: 1500,
    });

    popup?.remove();
    marker?.remove();

    // Leave enough time to finish flyTo animation, overzoomed, then set MaxBounds.
    setTimeout(() => {
      map.setMaxBounds(mineBlockBounds);
    }, 2000);

    setShowLayers(true);

    // Trigger the click event to hide marker and popup.
    const triggerEl = document.getElementById(triggerId);
    if (triggerEl) {
      triggerEl.click();
    }
  };

  useEffect(() => {
    const map = new Map({
      accessToken: MAPBOX_ACCESS_KEY,
      // @ts-ignore
      container: mapContainer.current,
      style: Styles.SATELLITE,
      center: defaultCenter,
      zoom: 3,
      attributionControl: false,
    });

    // only want to work with the map after it has fully loaded
    // if you try to add sources and layers before the map has loaded
    // things will not work properly
    map.on('load', (e) => {
      // Trigger on drag event when the map fully loaded.
      onDrag(e);
    });

    // Attach onDrag event.
    map.on('drag', onDrag);

    // Add zoom control.
    map.addControl(
      new NavigationControl({ showCompass: false }),
    );

    // Add scale control
    map.addControl(
      new ScaleControl({ maxWidth: 80, unit: 'metric' }),
      'top-left',
    );

    map.on('load', () => {
      // Add the popup.
      const popupContent = (
        <React.Fragment>
          <PopupContent
            title="NORI D Collection Site"
            triggerId={triggerId}
            items={[
              { title: 'Latitude', description: '9.871303' },
              { title: 'Longitude', description: '-117.508463' },
              { title: '% of Seabed', description: '0.021' },
            ]}
            footer={{
              title: 'Mine site',
              description: 'Within the Nori-D area, suitable mine '
                + 'sites are identified which will maximize productivity of '
                + 'the collector system while minimizing risks to the collector vehicle.',
            }}
          />
        </React.Fragment>
      );

      // Create popup element.
      const popup = new Popup({ closeButton: false })
        .setLngLat(DEFAULT_CENTER as [number, number])
        .setHTML(renderToString(popupContent))
        .setMaxWidth('500px')
        .setOffset(10);

      // Create marker and add to map.
      const markerEl = document.createElement('div');
      markerEl.className = 'marker-mine-site';
      const marker = new Marker(markerEl)
        .setLngLat(DEFAULT_CENTER as [number, number])
        .setPopup(popup)
        .addTo(map)
        .togglePopup();

      // Bind click event to link in popup.
      const element = document.getElementById(triggerId);
      if (element) {
        element.addEventListener('click', () => {
          panToMineBlock(map, popup, marker);
        });
      }

      // @ts-ignore
      setMapObject(map);
    });

    // When zoom in passed level 4, pan to Mine block and lock view.
    map.on('zoomend', (e: MapboxEvent) => {
      if (e.target.getZoom() <= 4) {
        panToMineBlock(map);
      }
    });

    map.scrollZoom.disable();
    map.on('wheel', (event) => {
      if (event.originalEvent.ctrlKey) {
        event.originalEvent.preventDefault();
        // @ts-ignore
        // eslint-disable-next-line no-underscore-dangle
        if (!map.scrollZoom._enabled) {
          map.scrollZoom.enable();
        }
        // @ts-ignore
        // eslint-disable-next-line no-underscore-dangle
      } else if (map.scrollZoom._enabled) {
        map.scrollZoom.disable();
      }
    });

    return () => map.remove();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpointMineBox]);

  return (
    <div className="relative">
      {/* @ts-ignore */}
      <div ref={mapContainer} style={{ width: '100%', height: '750px' }} />
      {(mapObject && showLayers) && (
        <React.Fragment>
          {/* @ts-ignore */}
          <O map={mapObject} id="Overlay" config={backgroundSource} />
          {/* @ts-ignore */}
          <SM map={mapObject} id="SeaMounts" endpoint={endpointSeaMounts} />
          {/* @ts-ignore */}
          <MB map={mapObject} id="MineBox" endpoint={endpointMineBox} />
          {/* @ts-ignore */}
          <MP map={mapObject} id="MinePath" endpoint={endpointMinePath} />
          {/* @ts-ignore */}
          <BC map={mapObject} id="BoxCores" endpoint={endpointBoxCores} />
        </React.Fragment>
      )}
      <Modal />
    </div>
  );
};

MapPlanView.propTypes = {

};

export default MapPlanView;
