import AppContextProvider from 'context/AppContextProvider';
import ModalContextProvider from 'context/ModalContextProvider';
import Router from './router';

const App = () => (
  <AppContextProvider>
    <ModalContextProvider>
      <Router />
    </ModalContextProvider>
  </AppContextProvider>
);

export default App;
