import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { IInfoProps } from 'interfaces';

import './Info.css';

const COMPONENT_CLASS = 'es-info';

const Info = ({
  title, subtitle, description, level, className,
}: IInfoProps) => {
  const componentClasses = classNames(
    COMPONENT_CLASS,
    'text-blueGray',
    className,
    {
      [`level-${level}`]: level,
    },
  );

  return (
    <article className={componentClasses}>
      <h3 className="mb-3 text-gray-400">{subtitle}</h3>
      <h2 className="mb-4 text-2xl">{title}</h2>
      <div className="description text-gray-800">{description}</div>
    </article>
  );
};

Info.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Info.defaultProps = {
  className: '',
};

export default Info;
