import React from 'react';
import PropTypes from 'prop-types';
import ChartKeyItem, { ChartKeyItemProps } from './chartKeyItem';

export interface ChartKeyProps {
  items: ChartKeyItemProps[];
}

const ChartKey = ({ items }: ChartKeyProps) => (
  <div className="container max-w-4xl mx-auto">
    <div className="grid grid-cols-4 gap-4">
      {items.map((item) => (
        <ChartKeyItem
          key={item.title}
          title={item.title}
          subtitle={item.subtitle}
          color={item.color}
          description={item.description || null}
        />
      ))}
    </div>
  </div>

);

ChartKey.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      description: PropTypes.string,
    }),
  ),
};

ChartKey.defaultProps = {
  items: [],
};

export default ChartKey;
