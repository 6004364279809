import React from 'react';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';
import './toggle.scss';

export interface FilterItemProps {
  id: string;
  title: string;
  icon: any;
  onChange(): void;
  value: boolean;
}

const FilterItem = ({
  id, title, icon, onChange, value,
}: FilterItemProps) => (
  <div className="flex items-center justify-between py-3 border-b-2">
    <div>{title}</div>
    <div className="controls flex items-center">
      {icon}
      <Toggle
        defaultChecked={value}
        id={id}
        icons={false}
        onChange={onChange}
      />
    </div>
  </div>
);

FilterItem.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool,
  icon: PropTypes.node,
};

FilterItem.defaultProps = {
  value: false,
  icon: null,
};

export default FilterItem;
