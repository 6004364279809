import React, { useEffect, useState, useContext } from 'react';
import { LngLat, LngLatBoundsLike } from 'mapbox-gl';

import MapOperationsContext from 'context/MapOperationsContext';
import ModalContext from 'context/ModalContext';
import axios from 'axios';

import MapOperations from 'components/map/MapOperations';
import CModal from 'components/map/modal/CModal';
import IconButton from 'components/button/iconButton';
import FilterItem from './FilterItem';
import LegendDepth from './LegendDepth';

import { API_SERVER } from '../../config';
import live from './images/live.svg';
import iconBC from './images/boxcore.svg';
import iconC from './images/collector.svg';
import iconMB from './images/mineBox.svg';
import iconMP from './images/minePath.svg';
import iconMMP from './images/minedMinePath.svg';
import LegendCoords from './LegendCoords';
import LegendSimulatedTime from './LegendSimulatedTime';

const ENDPOINT_BOXCORES = `${API_SERVER}/api/boxcores`;
const ENDPOINT_SEAMOUNTS = `${API_SERVER}/api/seamounts`;
const ENDPOINT_MINEBOX = `${API_SERVER}/api/minebox`;
const ENDPOINT_MINEPATH = `${API_SERVER}/api/minepath`;

const MapOperationsWidget = () => {
  const [loading, setLoading] = useState(false);
  const [mapBounds, setMapBounds] = useState([[null, null], [null, null]]);
  const [overlay, setOverlay] = useState(null);
  const [centerCoords, setCenterCoords] = useState(new LngLat(0, 0));
  const { setOpen, setContent, setTitle } = useContext(ModalContext);

  const {
    showBoxCores,
    showSeaMounts,
    showMineBox,
    showMinePath,
    showMinedMinePath,
    showCollector,
    setShowBoxCores,
    setShowSeaMounts,
    setShowMineBox,
    setShowMinePath,
    setShowMinedMinePath,
    setShowCollector,
  } = useContext(MapOperationsContext);

  const openLiveModal = (e: React.MouseEvent) => {
    e.preventDefault();
    setTitle('Collector Live View');
    setContent(<CModal />);
    setOpen(true);
  };

  useEffect(() => {
    setLoading(true);
    axios.get(`${API_SERVER}/api/map`)
      .then((res) => {
        const { map, overlay: responseOverlay } = res.data.data;
        setMapBounds(map.bounds);
        setOverlay(responseOverlay);
      })
      .then(() => setLoading(false))
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log('Error loading data Map data.', e.toString());
      });
  }, []);

  if (loading) return <div>Loading ...</div>;

  return (
    <section className="mt-6">

      <header className="flex items-center justify-between my-3">
        <button
          type="button"
          className="bg-blueGray-700 text-white rounded-full py-2 px-6 flex items-center space-x-1"
          onClick={() => {}}
        >
          <div className="h-2 w-2 rounded-full mr-2 bg-lime-500" />
          <span className="uppercase text-sm tracking-wide">Live</span>
        </button>

        <div className="flex gap-4">
          <LegendSimulatedTime />
          <LegendDepth />
          {centerCoords
            // @ts-ignore
            && <LegendCoords coords={centerCoords} />}
        </div>

      </header>
      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-3">
          <MapOperations
            endpointBoxCores={ENDPOINT_BOXCORES}
            endpointSeaMounts={ENDPOINT_SEAMOUNTS}
            endpointMinePath={ENDPOINT_MINEPATH}
            endpointMineBox={ENDPOINT_MINEBOX}
            backgroundSource={overlay}
            mapBounds={mapBounds as unknown as LngLatBoundsLike}
            onDrag={(e) => {
              const center = e.target.getCenter();
              setCenterCoords(new LngLat(center.lng, center.lat));
            }}
          />
        </div>

        <div className="col-span-1">
          <header className="py-5 border-b-4">
            <h3 className="text-lg">Customize</h3>
          </header>
          <div>
            <FilterItem
              title="Production block"
              id="mineBlockControl"
              value={showMineBox}
              onChange={() => setShowMineBox(!showMineBox)}
              icon={<img src={iconMB} alt="" />}
            />
            <FilterItem
              title="Collection path"
              id="minePathControl"
              value={showMinePath}
              onChange={() => setShowMinePath(!showMinePath)}
              icon={<img src={iconMP} alt="" />}
            />

            <FilterItem
              title="Collected path"
              id="minedMinePathControl"
              value={showMinedMinePath}
              onChange={() => setShowMinedMinePath(!showMinedMinePath)}
              icon={<img src={iconMMP} alt="" />}
            />

            <FilterItem
              title="Box cores"
              id="boxCoresControl"
              value={showBoxCores}
              onChange={() => setShowBoxCores(!showBoxCores)}
              icon={<img src={iconBC} alt="" />}
            />

            <FilterItem
              title="Collector"
              id="collectorControl"
              value={showCollector}
              onChange={() => setShowCollector(!showCollector)}
              icon={<img src={iconC} alt="" />}
            />

            <FilterItem
              title="Seamounts"
              id="seamountsControl"
              value={showSeaMounts}
              onChange={() => setShowSeaMounts(!showSeaMounts)}
              icon={null}
            />

            <IconButton
              title="Watch collection live"
              icon={<img src={live} alt="" />}
              subtitle="Observe seabed nodule collection"
              className="mt-4"
              block
              to="#"
              onClick={openLiveModal}
            />
          </div>
        </div>
      </div>

      <p className="mt-3 text-xs text-black">
        * Collection paths are for visual reference only and not final collecting plan.
      </p>

    </section>
  );
};

MapOperationsWidget.propTypes = {

};

export default MapOperationsWidget;
