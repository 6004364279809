import React, { useEffect } from 'react';

import Hero from 'components/hero/hero';
import PageTitleContext from 'context/PageTitleContext';
import { CONTAINER_WIDTH } from 'enums';

const Index = () => {
  const { setPageTitle } = React.useContext(PageTitleContext);

  useEffect(() => {
    setPageTitle('Intro');
  }, [setPageTitle]);

  return (
    <Hero
      subtitle="The Metals Company Labs"
      title="Monitoring Dashboard"
      lead="This initial version remote monitoring and reporting dashboard is a look into the future of transparent deep-sea operations. As part of TMC’s adaptive management strategy, the dashboard presents the eyes and ears of the collection system so that all stakeholders, including the regulators and the public, have access to a digital replica of the deep-sea operating environment."
      cta={{ title: 'Get Started', to: '/home' }}
      size={CONTAINER_WIDTH.XL4}
    >
      <div className="w-full md:w-4/5 mx-auto">
        <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
          <iframe
            src="https://player.vimeo.com/video/488171719"
            title="Mission: Environmental Baseline"
            style={{
              position: 'absolute', top: 0, left: 0, width: '100%', height: '100%',
            }}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          />
        </div>

      </div>
    </Hero>
  );
};

export default Index;
