import React from 'react';
import PropTypes from 'prop-types';
import { NotificationStatus } from 'enums';
import { IPropsWithClassName } from 'interfaces';

export enum BadgeType {
  GREEN = 'green',
  RED = 'red',
  YELLOW = 'yellow',
}

export interface BadgeProps extends IPropsWithClassName {
  type: BadgeType | NotificationStatus;
  text: string;
}

const Badge = ({ type, text, className }: BadgeProps) => {
  let color;
  let bgColor;

  switch (type) {
    case BadgeType.GREEN:
      color = 'text-green-600';
      bgColor = 'bg-green-100';
      break;
    case BadgeType.YELLOW:
      color = 'text-amber-800';
      bgColor = 'bg-amber-50';
      break;
    case BadgeType.RED:
      color = 'text-red-600';
      bgColor = 'bg-red-100';
      break;
    case NotificationStatus.IN_PROGRESS:
      color = 'text-white';
      bgColor = 'bg-blue-600';
      break;
    case NotificationStatus.IN_REVIEW:
      color = 'text-blueGray-900';
      bgColor = 'bg-blue-300';
      break;
    case NotificationStatus.EVENT_DETECTED:
      color = 'text-blueGray-900';
      bgColor = 'bg-blue-100';
      break;
    default:
      color = 'text-green-600';
      bgColor = 'bg-green-100';
      break;
  }

  const classes = [
    'inline-flex',
    'items-center',
    'justify-center',
    'px-3',
    'py-1',
    'text-xs',
    'leading-none',
    'uppercase',
    'rounded',
    color,
    bgColor,
    className,
  ];
  return (
    <div className={classes.join(' ')}>
      {text}
    </div>
  );
};

Badge.propTypes = {
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Badge.defaultProps = {
  className: '',
};

export default Badge;
