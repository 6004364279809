import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const buttonClasses = {
  primary: [
    'px-4',
    'py-3',
    'text-xs',
    'uppercase',
    'bg-blueGray',
    'dark:bg-white',
    'text-white',
    'dark:text-blueGray',
    'border-2',
    'border-transparent',
    'hover:bg-blueGray-800',
    'dark:hover:bg-blue-500',
    'dark:hover:text-white',
    'focus:bg-blueGray-800',
    'active:bg-blueGray-900',
  ],
  secondary: [
    'px-4',
    'py-3',
    'text-xs',
    'uppercase',
    'bg-gray',
    'text-black',
    'border-2',
    'border-transparent',
    'hover:bg-gray-dark',
    'focus:bg-gray-dark',
    'active:bg-gray-darkest',
  ],
  outline: [
    'px-4',
    'py-3',
    'text-xs',
    'uppercase',
    'bg-transparent',
    'text-blue-500',
    'dark:text-white',
    'border-2',
    'border-blue-500',
    'dark:border-white',
    'hover:bg-blue-500',
    'dark:hover:bg-white',
    'hover:text-white',
    'dark:hover:text-blueGray',
    'focus:bg-blue-500',
    'focus:text-white',
    'active:bg-blue-900',
    'active:border-blue-900',
  ],
  link: [
    'text-black',
    'font-medium',
    'text-lg',
    'border-b-4',
    'px-0',
    'p-0.7',
    'border-yellow-300',
  ],
};

export interface ButtonProps {
  title: string,
  to: string,
  type: keyof typeof buttonClasses,
  blank: boolean,
}

const Button = ({
  title,
  to,
  type,
  blank,
}: ButtonProps) => {
  const extraClasses = buttonClasses[type];

  const classes = [
    'font-medium',
    'inline-flex',
    'leading-none',
    'focus-visible:ring-2',
    'focus-visible:ring-amber-400',
    'disabled:opacity-50',
    'disabled:pointer-events-none',
    'transition-colors',
  ].concat(extraClasses);
  return !blank ? (
    <Link to={to} className={classes.join(' ')}>
      {title}
    </Link>
  ) : (
    <a href={to} className={classes.join(' ')} target="_blank" rel="noreferrer">{title}</a>
  );
};

Button.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['primary', 'secondary', 'outline', 'link']),
  blank: PropTypes.bool,
};

Button.defaultProps = {
  type: 'primary',
  blank: null,
};

export default Button;
