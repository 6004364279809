import React, { useEffect, useState } from 'react';
import { LngLat, LngLatBoundsLike } from 'mapbox-gl';
import axios from 'axios';

import MapPlanView from 'components/map/MapPlanView';
import { API_SERVER } from '../../config';
import LegendDepth from './LegendDepth';
import LegendCoords from './LegendCoords';
import LegendLayers from './LegendLayers';

import './mapPlanViewWidget.scss';

const ENDPOINT_BOXCORES = `${API_SERVER}/api/boxcores`;
const ENDPOINT_SEAMOUNTS = `${API_SERVER}/api/seamounts`;
const ENDPOINT_MINEBOX = `${API_SERVER}/api/minebox`;
const ENDPOINT_MINEPATH = `${API_SERVER}/api/minepath`;

const MapPlanViewWidget = () => {
  const [loading, setLoading] = useState(false);
  const [centerCoords, setCenterCoords] = useState(new LngLat(0, 0));
  const [overlay, setOverlay] = useState(null);
  const [mapBounds, setMapBounds] = useState([new LngLat(0, 0), new LngLat(0, 0)]);

  useEffect(() => {
    setLoading(true);
    setCenterCoords(new LngLat(-117.06881059488416, 10.370702420301185));
    axios.get(`${API_SERVER}/api/map`)
      .then((res) => {
        const { map, overlay: responseOverlay } = res.data.data;
        setOverlay(responseOverlay);
        setMapBounds(map.bounds);
      })
      .then(() => setLoading(false))
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log('Error loading data Map data.', e.toString());
      });
  }, []);

  if (loading) return <div>Loading ...</div>;

  return (
    <div className="relative font-body">
      <MapPlanView
        endpointMineBox={ENDPOINT_MINEBOX}
        endpointBoxCores={ENDPOINT_BOXCORES}
        endpointSeaMounts={ENDPOINT_SEAMOUNTS}
        endpointMinePath={ENDPOINT_MINEPATH}
        backgroundSource={overlay}
        mineBlockBounds={mapBounds as LngLatBoundsLike}
        onDrag={(e) => {
          const center = e.target.getCenter();
          setCenterCoords(new LngLat(center.lng, center.lat));
        }}
      />
      <div className="legend-depth">
        <LegendDepth />
      </div>

      <div className="legend-coords">
        {/* @ts-ignore */}
        <LegendCoords coords={centerCoords} />
      </div>

      <div className="legend-layers">
        <LegendLayers />
      </div>
    </div>
  );
};

export default MapPlanViewWidget;
