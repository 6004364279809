import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Map, Popup } from 'mapbox-gl';
import { renderToString } from 'react-dom/server';

import MapOperationsContext from 'context/MapOperationsContext';
import ModalContext from 'context/ModalContext';
import { MapLayerProps } from 'interfaces';
import { BoxCoreCircle, BoxCoreCircleShadow } from '../Mapbox';
import PopupContent from '../PopupContent';
import toggleLayer from './utils';
import BcModal from '../modal/BCModal';

const BC = ({ map, endpoint, id }: MapLayerProps) => {
  const { showBoxCores } = useContext(MapOperationsContext);
  const { setOpen, setTitle, setContent } = useContext(ModalContext);

  useEffect(() => {
    const sourceId = `${id}Source`;
    const circleLayerId = `${id}Circle`;
    const shadowLayerId = `${id}Shadow`;

    if (!map.getSource(sourceId)) {
      map.addSource(sourceId, {
        type: 'geojson',
        data: endpoint,
      });

      // Add a box shadow
      map.addLayer({
        id: shadowLayerId,
        type: 'circle',
        source: sourceId,
        paint: BoxCoreCircleShadow,
      });

      // Add a symbol layer
      map.addLayer({
        id: circleLayerId,
        type: 'circle',
        source: sourceId,
        paint: BoxCoreCircle,
      });
    }

    // Bind click event on boxCores feature.
    map.on('click', circleLayerId, (e: any) => {
      const boxCore = e.features[0].properties;
      const {
        stationID, waterDepth, lat, lng,
      } = boxCore;

      // Center the map to the clicked box core.
      map.panTo([lng, lat]);

      // Construct the popup content.
      const triggerId = `bc${stationID}`;
      const popupContent = (
        <React.Fragment>
          <PopupContent
            title={stationID}
            triggerId={triggerId}
            items={[
              { title: 'Depth', description: `-${waterDepth} m` },
              { title: 'UTM East', description: lat.toString() },
              { title: 'UTM North', description: lng.toString() },
            ]}
            footer={{
              title: 'Box core',
              description: 'A sample of seabed extracted to faciliate biological '
                + 'and geological data collection.',
            }}
          />
        </React.Fragment>
      );

      // Create the popup and attach to the map.
      new Popup({ closeButton: false })
        .setLngLat(e.lngLat)
        .setHTML(renderToString(popupContent))
        .setMaxWidth('500px')
        .setOffset(5)
        .addTo(map);

      // Attach click event to the link in pop up.
      const element = document.getElementById(triggerId);
      if (element) {
        element.addEventListener('click', () => {
          // Prepare and open modal.
          setTitle('Box core');
          setContent(<BcModal data={boxCore} />);
          setOpen(true);
        });
      }
    });

    // Change the cursor to a pointer when the mouse is over the places layer.
    map.on('mouseenter', circleLayerId, () => {
      map.getCanvas().style.cursor = 'pointer';
    });

    // Change it back to a pointer when it leaves.
    map.on('mouseleave', circleLayerId, () => {
      map.getCanvas().style.cursor = '';
    });

    // Handle show hide this layer base on the current context.
    toggleLayer(map, [circleLayerId, shadowLayerId], showBoxCores);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, showBoxCores]);

  return null;
};

BC.propTypes = {
  map: PropTypes.instanceOf(Map).isRequired,
  id: PropTypes.string.isRequired,
  endpoint: PropTypes.string.isRequired,
};

export default BC;
