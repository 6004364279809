import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';

export interface NavBarProps {
  fontSize?: string;
  itemClassName?: string;
}

const navBarLinks = [
  {
    to: '/environment',
    title: 'Environment',
  },
  {
    to: '/operations',
    title: 'Operations',
  },
];

const NavBar = ({ fontSize = 'text-2xl', itemClassName = '' }: NavBarProps) => {
  const location = useLocation();
  const linkClasses = [
    fontSize,
    'font-regular',
    'hover:text-black',
    itemClassName,
  ];

  return (
    <nav className="hidden md:flex space-x-10">
      {navBarLinks.map((link) => {
        const match = location.pathname === link.to;

        return (
          <NavLink
            key={link.to}
            to={link.to}
            title={link.title}
            className={classNames(linkClasses, { 'active text-black': match, 'text-gray-900': !match })}
          >
            {link.title}
          </NavLink>
        );
      })}
    </nav>
  );
};

export default NavBar;
