import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { select, selectAll } from 'd3';
import _debounce from 'lodash/debounce';

import { IChartLevelsProps } from 'interfaces';
import {
  makeXAxis,
  makeLevels,
  makeTitle,
  makeIndicators,
} from './svg-utils';
import {
  MARGINS,
  HEIGHT,
  WIDTH,
  LINES_AREA_HEIGHT,
  DEBOUNCE_TIME,
  LEVELS,
} from './config';

import './ChartLevels.css';

const X_AXIS_MARGIN = 15;

const ChartLevels = ({
  periods,
  indicators,
  beforeActive,
  className,
}: IChartLevelsProps) => {
  const chartRef = React.createRef<HTMLDivElement>();
  const componentClasses = classNames('levels-chart', className);
  const [containerWidth, setContainerWidth] = useState(0);

  const drawChart = (element: HTMLElement, width: number) => {
    const charWidth = width || WIDTH;
    const realWidth = charWidth - MARGINS.right - MARGINS.left;
    element.innerHTML = '';

    const svg = select(element).append('svg')
      .attr('width', charWidth)
      .attr('height', HEIGHT + MARGINS.top + MARGINS.bottom);
    const titleGroup = svg.append('g');
    const mainGroup = svg.append('g')
      .attr('transform', `translate(${MARGINS.left}, ${MARGINS.top})`);

    const levelsTop = makeLevels(mainGroup, LEVELS, realWidth, 0, true);
    const { height: levelsBoundsHeight = 0 } = levelsTop.node()?.getBoundingClientRect() || {};
    makeLevels(mainGroup, LEVELS, realWidth, LINES_AREA_HEIGHT + levelsBoundsHeight, false);

    const axisPosition = LINES_AREA_HEIGHT + levelsBoundsHeight * 2;
    if (periods) {
      makeXAxis(mainGroup, periods, realWidth, axisPosition, X_AXIS_MARGIN);
    }
    makeTitle(titleGroup, charWidth, 'Predicted');
    const indicatorsYPosition = levelsBoundsHeight + LINES_AREA_HEIGHT / 2;
    makeIndicators(
      mainGroup,
      periods,
      indicators,
      realWidth,
      indicatorsYPosition,
      X_AXIS_MARGIN,
      beforeActive,
    );
    /* Moving levels text into a new container to bring more deep */
    selectAll('g.levels').each(function eachAppendNode() {
      mainGroup.node()?.append(this as Node);
    });
  };

  useEffect(() => {
    if (chartRef.current) {
      const width = chartRef.current.offsetWidth;
      drawChart(chartRef.current as HTMLElement, width);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, containerWidth]);

  useEffect(() => {
    const container = chartRef.current;
    if (!container) {
      return;
    }

    const onResize = _debounce(() => {
      if (containerWidth !== container.offsetWidth) {
        setContainerWidth(container.offsetWidth);
      }
    }, DEBOUNCE_TIME);

    window.addEventListener('resize', onResize);

    // eslint-disable-next-line consistent-return
    return () => window.removeEventListener('resize', onResize);
  }, [containerWidth, setContainerWidth, chartRef]);

  return (
    <div className={componentClasses} ref={chartRef} />
  );
};

ChartLevels.propTypes = {
  periods: PropTypes.array.isRequired,
  indicators: PropTypes.array,
  className: PropTypes.string,
  beforeActive: PropTypes.bool,
};

ChartLevels.defaultProps = {
  className: '',
  beforeActive: true,
  indicators: null,
};

export default ChartLevels;
