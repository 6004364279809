import React, { Fragment, useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import _isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';

import { ReactComponent as CloseIcon } from 'images/close.svg';
import { IPropsWithClassName } from 'interfaces';

export interface ToastItem {
  id: string;
  title: string;
  message: string;
  removed?: boolean;
  onClose?(toastItem: ToastItem): void;
  onClick?(toastItem: ToastItem): void;
}

export interface ToastProps extends ToastItem, IPropsWithClassName {}

const TIMEOUT = 5000;

const Toast = ({
  id, title, message, onClose, onClick, className,
}: ToastProps) => {
  const [show, setShow] = useState(true);

  const onToastLeave = () => {
    if (_isFunction(onClose)) {
      onClose({ id, title, message });
    }
  };

  const onToastClick = () => {
    if (_isFunction(onClick)) {
      onClick({ id, title, message });
    }
    setShow(false);
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onToastClick();
    }
  };

  const onCloseClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setShow(false);
  };

  /* Adding auto close */
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (show) {
        setShow(false);
      }
    }, TIMEOUT);

    return () => clearTimeout(timeoutId);
  }, [id, title, message, show, setShow, onClose]);

  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      afterLeave={() => onToastLeave()}
    >
      <div className={`max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden ${className}`}>
        <div className="p-4" onClick={onToastClick} role="button" tabIndex={0} onKeyDown={onKeyDown}>
          <div className="flex items-start">
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-sm font-medium text-gray-900">{title}</p>
              <p className="mt-1 text-sm text-gray-500">{message}</p>
            </div>
            <div className="ml-4 flex-shrink-0 flex">
              <button
                type="button"
                className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={onCloseClick}
              >
                <span className="sr-only">Close</span>
                <CloseIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};

Toast.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Toast.defaultProps = {
  className: null,
};

export default Toast;
