import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';

import App from 'App';
import reportWebVitals from 'reportWebVitals';

import 'styles/global.css';

const tagManagerArgs = {
  gtmId: 'GTM-KG7LFXV',
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <App />,
  document.getElementById('tmc-app'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
