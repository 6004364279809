import React from 'react';
import _map from 'lodash/map';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IPropsWithClassName } from 'interfaces';
import { NotificationStatus } from 'enums';

interface NotificationItemBarProps extends IPropsWithClassName {
  status: NotificationStatus;
}

const NotificationItemBar = ({
  status,
  className,
}: NotificationItemBarProps) => {
  const componentClasses = classNames(
    'flex',
    className,
  );

  const validStatus = [
    NotificationStatus.EVENT_DETECTED,
    NotificationStatus.IN_REVIEW,
    NotificationStatus.IN_PROGRESS,
    NotificationStatus.RESOLVED,
  ];
  const statusIndex = validStatus.indexOf(status);
  const colors = [
    'bg-blue-100',
    'bg-blue-300',
    'bg-blue-600',
    'bg-blue-900',
  ];

  return (
    <div className={componentClasses}>
      {
                _map(validStatus, (statusItem, index) => {
                  const bgColor = statusIndex >= index ? colors[index] : 'bg-gray-300';

                  return (
                    <div key={statusItem} className={`w-1/5 mr-1 h-1 ${bgColor}`} />
                  );
                })
            }
    </div>
  );
};

NotificationItemBar.propTypes = {
  status: PropTypes.string.isRequired,
  className: PropTypes.string,
};

NotificationItemBar.defaultProps = {
  className: null,

};

export default NotificationItemBar;
