import React from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';

import { INotificationItem, IPropsWithClassName } from 'interfaces';

import NotificationItem from './NotificationItem';

export interface NotificationsProps extends IPropsWithClassName {
  items: INotificationItem[];
}

const Notifications = ({
  items,
  className,
}: NotificationsProps) => (
  <article className={className}>
    {
      _map(items, (notification) => (
        notification && notification.status
          ? (
            <NotificationItem
              key={notification.status + notification.date}
              {...notification}
            />
          )
          : null))
    }
  </article>
);

Notifications.propTypes = {
  items: PropTypes.array,
  className: PropTypes.string,
};

Notifications.defaultProps = {
  className: null,
  items: [],
};

export default Notifications;
