import { IIndicatorsLevels } from 'interfaces';

export const MARGINS = {
  top: 30,
  right: 0,
  bottom: 0,
  left: 0,
};
export const WIDTH = 960 - MARGINS.right - MARGINS.left;
export const HEIGHT = 330 - MARGINS.top - MARGINS.bottom;
export const LINES_AREA_HEIGHT = 90;
export const DEBOUNCE_TIME = 250;
export const INDICATOR_NODE_RADIUS = 4;
export const INDICATOR_LINK_WIDTH = 2.5;

export const COLORS = {
  INDICATOR_LEVEL_1: '#F3FDEC',
  INDICATOR_LEVEL_LINE_1: '#8AE93F',
  INDICATOR_LEVEL_2: '#FFFAE6',
  INDICATOR_LEVEL_LINE_2: '#FFCC00',
  INDICATOR_LEVEL_3: '#FFF2E9',
  INDICATOR_LEVEL_LINE_3: '#FC8721',
  INDICATOR_NODE: '#7C8E9A',
  LINE_SEPARATOR: '#EEEEEF',
  SEPARATOR: '#DDD',
  HALF_SEPARATOR: '#7C8E9A',
  TEXTS: '#767676',
};

export const LEVELS: IIndicatorsLevels[] = [
  { name: 'Level 1', color: COLORS.INDICATOR_LEVEL_1, lineColor: COLORS.INDICATOR_LEVEL_LINE_1 },
  { name: 'Level 2', color: COLORS.INDICATOR_LEVEL_2, lineColor: COLORS.INDICATOR_LEVEL_LINE_2 },
  { name: 'Level 3', color: COLORS.INDICATOR_LEVEL_3, lineColor: COLORS.INDICATOR_LEVEL_LINE_3 },
];
