import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import PageTitleContext from 'context/PageTitleContext';
import TokenProvider from 'context/TokenProvider';
import { IPropsWithChildren } from 'interfaces';
import ToastProvider from './ToastProvider';

const AppContextProvider = ({ children }: IPropsWithChildren) => {
  const [pageTitle, setPageTitle] = useState('');

  const updatePageClient = (title:string) => {
    setPageTitle(title);
  };

  const pageTitleValue = useMemo(() => ({
    pageTitle,
    setPageTitle: updatePageClient,
  }), [pageTitle]);

  return (
    <PageTitleContext.Provider value={pageTitleValue}>
      <TokenProvider>
        <ToastProvider>
          {children}
        </ToastProvider>
      </TokenProvider>
    </PageTitleContext.Provider>
  );
};

AppContextProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export default AppContextProvider;
