import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'mapbox-gl';

interface ConfigProps {
  url: string,
  coordinates: number[][]
}

interface OverlayProps {
  map: Map,
  config: ConfigProps,
  id: string
}

const O = ({ map, config, id }: OverlayProps) => {
  useEffect(() => {
    const sourceId = `${id}Source`;
    if (!map.getSource(sourceId)) {
      map.addSource(sourceId, {
        type: 'image',
        ...config,
      });

      map.addLayer({
        id: 'overlay',
        type: 'raster',
        source: sourceId,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, config]);

  return null;
};

O.propTypes = {
  map: PropTypes.instanceOf(Map).isRequired,
  id: PropTypes.string.isRequired,
};

export default O;
