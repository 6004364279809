import React from 'react';
import PropTypes from 'prop-types';

interface PopupContentProps {
  title: string;
  items: any;
  footer: any;
  triggerId: string;
}

const PopupContent = ({
  title, items, footer, triggerId,
}: PopupContentProps) => (
  <div className="w-80">
    <header>
      <h5 className="font-medium text-base text-blue-700">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className="cursor-pointer" id={triggerId}>{title}</a>
      </h5>
    </header>

    <div className="grid grid-flow-col grid-cols-3 gap-4 my-2">
      {items.map((item: any) => (
        <div key={item.title}>
          <div className="uppercase font-medium">{item.title}</div>
          <div>{item.description}</div>
        </div>
      ))}
    </div>

    <footer className="border-t">
      <div className="font-medium text-sm my-2">{footer.title}</div>
      <div>{footer.description}</div>
    </footer>
  </div>
);

PopupContent.propTypes = {
  title: PropTypes.string.isRequired,
  triggerId: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  ),
  footer: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }),
};

PopupContent.defaultProps = {
  items: [],
  footer: {},
};

export default PopupContent;
