import * as d3 from 'd3';
import {
  NODES_CONFIG, MARGINS, WIDTH, HEIGHT, COLORS,
} from './config';

const tooltipClasses = [
  'font-lato',
  'chart-tooltip',
  'absolute',
  'text-xs',
  'bg-blueGray',
  'opacity-0',
  'text-white',
  'py-2',
  'px-3',
  'text-center',
  'rounded',
  'pointer-events-none',
  'select-none',
  'transition-opacity',
];

const gradients = [
  {
    name: 'service-function',
    from: COLORS.SERVICE,
    to: COLORS.FUNCTION,
  },
  {
    name: 'function-indicator',
    from: COLORS.FUNCTION,
    to: COLORS.INDICATOR_LINK,
  },
];
/**
 * Make the chart container
 * @param container
 * @param cleanContainer removes the container content
 */
const makeSvg = (container: HTMLElement, cleanContainer: boolean = true) => {
  if (cleanContainer && container) {
    container.innerHTML = '';
  }
  d3.select(container)
    .append('div')
    .attr('class', tooltipClasses.join(' '));

  const svg = d3.select(container).append('svg')
    .attr('width', WIDTH + MARGINS.left + MARGINS.right)
    .attr('height', HEIGHT + MARGINS.top + MARGINS.bottom)
    .append('g')
    .attr('transform', `translate(${MARGINS.left},${MARGINS.top})`);
  gradients.forEach(({ name, from, to }) => {
    const serviceFunctionGradient = svg.append('defs')
      .append('linearGradient')
      .attr('id', `linear-gradient-${name}`)
      .attr('gradientTransform', 'rotate(90)');

    serviceFunctionGradient.append('stop')
      .attr('offset', '20%')
      .attr('stop-color', from);

    serviceFunctionGradient.append('stop')
      .attr('offset', '70%')
      .attr('stop-color', to);
  });

  return svg;
};

/**
 * Link width depending on Nodes Number
 *
 * @param count
 */
const getLinkWidth = (count: number): number => {
  if (count > 3) return NODES_CONFIG.LINK.width;
  if (count > 2) return 10;
  if (count > 1) return 12;
  return 18;
};

/**
 * Make a vertical link
 *
 * @param source
 * @param target
 */
const makeLink = (source: [number, number], target: [number, number]) => d3.linkVertical()({
  source,
  target,
});

const selectElement = (selector: string) => d3.select(selector);

export {
  makeSvg,
  getLinkWidth,
  makeLink,
  selectElement,
};
