export const indicators1 = [
  {
    label: 'Turbidity',
    values: [0.5, 0.51, 0.52, 0.53, 0.54, 0.55, 0.56, 0.57],
    unit: 'NTU',
    interval: 1000,
  },
  {
    label: 'Ph',
    values: [7.81, 7.82, 7.83, 7.84],
    unit: '',
    interval: 4000,
  },
  {
    label: 'Temp',
    values: [1.9, 1.91, 1.92, 1.93, 1.94],
    unit: 'C',
    interval: 2000,
  },
  {
    label: 'O2',
    values: [4.7, 4.8, 4.9],
    unit: 'mg L-1',
    interval: 1500,
  },
  {
    label: 'Nitrate',
    values: [23.56, 23.57, 23.58],
    unit: 'mmol L-1',
    interval: 1200,
  },
];

export const indicators2 = [
  {
    label: 'Depth',
    values: [4224, 4224.5, 4225],
    unit: 'm',
    interval: 3000,
  },
  {
    label: 'Pressure',
    values: [420.036, 420.037],
    unit: 'atm',
    interval: 3000,
  },
  {
    label: 'Salinity',
    values: [34.7, 34.8],
    unit: 'PPT',
    interval: 1200,
  },
  {
    label: 'Density',
    values: [1046.91, 1046.92, 1046.93, 1046.94, 1046.95, 1046.96],
    unit: 'kg/m^3',
    interval: 1400,
  },
];
