import React from 'react';

interface BCDetailProps {
  label: string;
  description: string;
}

const BCDetail = ({ label, description }: BCDetailProps) => (
  <div className="p-4 bg-white border-b border-gray-300">
    <h5 className="text-blueGray text-sm mb-2">{label}</h5>
    <div className="text-sm text-gray-400">{description}</div>
  </div>
);

const BcModal = ({ data }: any) => {
  const {
    sampleCollectionMethod,
    photoFileName,
    lng, lat,
    waterDepth,
    waterDepthUnits,
    sampleDate,
    researchVessel,
    volumeSampled,
  } = data || {};

  const photoUrl = photoFileName || 'https://via.placeholder.com/800x500.jpg?text=Box+Core';
  return (
    <div className="text-white grid grid-cols-3">
      <div className="col-span-2">
        <img src={photoUrl} alt="" className="w-full" />
      </div>
      <div className="flex flex-col">
        <BCDetail
          label="Sample"
          description="Top photo of boxcores after water drained of and before processing"
        />
        <BCDetail
          label="Method"
          description={sampleCollectionMethod}
        />
        <BCDetail
          label="Research Vessel"
          description={researchVessel}
        />
        <BCDetail
          label="Volume Sampled"
          description={volumeSampled}
        />

        <div className="p-4 bg-gray-100 text-blueGray text-sm flex-1">
          <h4 className="mb-2 text-gray-600 uppercase">Sample time</h4>
          <div>{sampleDate}</div>

          <div className="grid grid-cols-2 mt-6">
            <div>
              <h4 className="mb-2 text-gray-600 uppercase">Location</h4>
              <div>{`${lat} ${lng}`}</div>
            </div>

            <div>
              <h4 className="mb-2 text-gray-600 uppercase">Elevation</h4>
              <div>{`-${waterDepth} ${waterDepthUnits}`}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BcModal;
