import { useContext, useEffect } from 'react';
// @ts-ignore
import PropTypes from 'prop-types';
import { Map } from 'mapbox-gl';
// @ts-ignore
import MapOperationsContext from 'context/MapOperationsContext';
// @ts-ignore
import { MapLayerProps } from 'interfaces';
import { MineBoxLine } from '../Mapbox';
import toggleLayer from './utils';

const MB = ({ map, endpoint, id }: MapLayerProps) => {
  const { showMineBox } = useContext(MapOperationsContext);

  useEffect(() => {
    const sourceId = `${id}Source`;
    const lineLayerId = `${id}Line`;

    if (!map.getSource(sourceId)) {
      map.addSource(sourceId, {
        type: 'geojson',
        data: endpoint,
      });

      map.addLayer({
        id: lineLayerId,
        type: 'line',
        source: sourceId,
        paint: MineBoxLine,
      });
    }

    // Handle show hide this layer base on the current context.
    toggleLayer(map, [lineLayerId], showMineBox);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, showMineBox]);

  return null;
};

MB.propTypes = {
  map: PropTypes.instanceOf(Map).isRequired,
  id: PropTypes.string.isRequired,
  endpoint: PropTypes.string.isRequired,
};

export default MB;
