/* eslint-disable max-len */

import React, { useEffect, useState, useMemo } from 'react';
import { filter } from 'rxjs';
import _isEqual from 'lodash/isEqual';

import Hero from 'components/hero/hero';
import Section, { BG_COLOR } from 'components/section/Section';
import SectionHeader from 'components/section/SectionHeader';
import ContactForm from 'components/ContactForm/ContactForm';
import Notifications from 'components/notifications/Notifications';
import { ToastItem } from 'components/toast/toast';
import PageTitleContext from 'context/PageTitleContext';
import { CONTAINER_WIDTH } from 'enums';
import notifications from 'mocks/notifications.mock';
import predictedNotifications from 'mocks/notificationsPredicted.mock';
import MapOperationsContext from 'context/MapOperationsContext';
import { ToastContext } from 'context/ToastProvider';
import { IMapTicker, mapTicker, restartTicker } from 'utils/mapTicker';
import { sendNotifications } from 'utils/NotificationsService';
import { INotificationItem } from 'interfaces';
import MapOperationsWidget from '../widgets/map/MapOperationsWidget';
import sanitizeUrl from '../utils/sanitizeUrl';

const Operations = () => {
  const { setPageTitle } = React.useContext(PageTitleContext);
  const { addToastItems } = React.useContext(ToastContext);
  const [showBC, setShowBC] = useState(true);
  const [showSM, setShowSM] = useState(true);
  const [showMB, setShowMB] = useState(true);
  const [showMP, setShowMP] = useState(true);
  const [showMMP, setShowMMP] = useState(true);
  const [showC, setShowC] = useState(true);
  const [st, setST] = useState('');

  const mapOperatorsContextValue = useMemo(() => ({
    showBoxCores: showBC,
    showSeaMounts: showSM,
    showMineBox: showMB,
    showMinePath: showMP,
    showMinedMinePath: showMMP,
    showCollector: showC,
    simulatedTime: st,
    setShowBoxCores: (value: boolean) => setShowBC(value),
    setShowSeaMounts: (value: boolean) => setShowSM(value),
    setShowMineBox: (value: boolean) => setShowMB(value),
    setShowMinePath: (value: boolean) => setShowMP(value),
    setShowMinedMinePath: (value: boolean) => setShowMMP(value),
    setShowCollector: (value: boolean) => setShowC(value),
    setSimulatedTime: (value: string) => setST(value),
  }), [showBC, showMB, showMP, showSM, showMMP, showC, st]);
  const [notificationsItems, setNotificationItems] = useState<INotificationItem[]>([]);
  const [
    notificationsPredictedItems,
    setNotificationPredictedItems,
  ] = useState<INotificationItem[]>([]);

  useEffect(() => {
    setPageTitle('Operations');
    restartTicker();
  }, [setPageTitle]);

  useEffect(() => {
    const onToastClick = (toastItem: ToastItem) => {
      const id = sanitizeUrl(toastItem?.title);
      const element = document.getElementById(id);

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
      const markNotificationItem = (items:INotificationItem[], itemToSearch: ToastItem) : INotificationItem[] => items.map((item: INotificationItem) => {
        if (item.date === itemToSearch.id) {
          item.collapsed = false;
        }
        return item;
      });

      let notificationItem = notificationsPredictedItems.find((item:INotificationItem) => item.date === toastItem.id);
      if (notificationItem) {
        const newNotificationItems = markNotificationItem(notificationsPredictedItems, toastItem);
        setNotificationPredictedItems(newNotificationItems);
      } else {
        notificationItem = notificationsItems.find((item:INotificationItem) => item.date === toastItem.id);
        if (notificationItem) {
          const newNotificationItems = markNotificationItem(notificationsPredictedItems, toastItem);
          setNotificationItems(newNotificationItems);
        }
      }
    };

    let newNotifications:INotificationItem[] = [];
    if (notificationsPredictedItems?.length) {
      newNotifications = [...notificationsPredictedItems];
    }
    if (notificationsItems?.length) {
      newNotifications = [...newNotifications, ...notificationsPredictedItems];
    }
    if (newNotifications.length) {
      const toasts:ToastItem[] = newNotifications.map((notification: INotificationItem) => ({
        id: notification.date,
        title: notification.title,
        message: notification.description || '',
        onClick: onToastClick,
      }));
      if (toasts.length) {
        addToastItems(toasts);
      }
    }
  }, [notificationsItems, notificationsPredictedItems, addToastItems]);

  useEffect(() => {
    const subscription$ = mapTicker.pipe(
      filter((value: IMapTicker) => value.current % 50 === 0), /* Just take 30 percent of ticks */
    ).subscribe((value: IMapTicker) => {
      const { currentDate } = value;
      const currentTimestamp = currentDate.getTime();

      const filterNotifications = (items: INotificationItem[][]):INotificationItem[] => {
        const itemsToReturn: INotificationItem[] = [];
        items.forEach((notification:INotificationItem[]) => {
          let item;
          notification.forEach((notificationItem: INotificationItem) => {
            const { timestamp = currentTimestamp + 1 } = notificationItem;
            if (currentTimestamp >= timestamp) {
              item = notificationItem;
            }
          });
          if (item) {
            itemsToReturn.push(item);
          }
        });

        return itemsToReturn;
      };

      const currentNotifications = filterNotifications(notifications);
      const currentPredictedNotifications = filterNotifications(predictedNotifications);

      let updateNotifications = false;
      if (currentNotifications.length) {
        updateNotifications = !_isEqual(currentNotifications, notificationsItems);
        if (updateNotifications) {
          setNotificationItems(currentNotifications);
        }
      }

      let updatePredictedNotifications = false;
      if (currentPredictedNotifications.length) {
        updatePredictedNotifications = !_isEqual(
          currentPredictedNotifications,
          notificationsPredictedItems,
        );
        if (updatePredictedNotifications) {
          setNotificationPredictedItems(currentPredictedNotifications);
        }
      }

      if (updateNotifications || updatePredictedNotifications) {
        sendNotifications([...currentNotifications, ...currentPredictedNotifications]);
      }
    });

    return () => subscription$.unsubscribe();
  }, [
    notificationsItems,
    notificationsPredictedItems,
    setNotificationItems,
    setNotificationPredictedItems,
  ]);

  return (
    <React.Fragment>
      <Hero
        title="Operations"
        lead="Realtime production data to enable the regulator to verify collection planning, nodule/mineral collection data, revenue, royalties and asset monitoring."
        size={CONTAINER_WIDTH.XL4}
      />
      <Section>
        <SectionHeader
          title="Ecosystem & Equipment Locations"
          size={CONTAINER_WIDTH.XL4}
        >
          The live view of the map shows an example of how operations would progress during production. Sample
          data is used to show how equipment locations would be updated in the dashboard in near-real-time.
        </SectionHeader>

        <MapOperationsContext.Provider value={mapOperatorsContextValue}>
          <MapOperationsWidget />
        </MapOperationsContext.Provider>

      </Section>

      <Section bgColor={BG_COLOR.LIGHT_GRAY} size={CONTAINER_WIDTH.XL4}>
        <SectionHeader title="Environmental Notifications">
          Notifications provide a glance into what environmental scenarios might occur during operation and
          how the Adaptive Management System would take appropriate action in response to sudden or predicted
          events in order to maintain acceptable operations.
        </SectionHeader>
        {
          notificationsItems.length
            ? (
              <React.Fragment>
                <h3 className="text-2xl text-center">Recent Events</h3>
                <p className="mt-3 text-center">
                  We track transient environmental events such as the presence of
                  natural habitats and whale falls.
                </p>
                <Notifications items={notificationsItems} className="mt-5" />
              </React.Fragment>
            )
            : <React.Fragment />
        }
        {
          notificationsPredictedItems.length
            ? (
              <React.Fragment>
                <h3 className="mt-10 text-2xl text-center">Predicted Events</h3>
                <p className="mt-3 text-center">
                  We predict future situations based on simulated ecosystem
                  indicators and take preventative action.
                </p>
                <Notifications items={notificationsPredictedItems} className="mt-5" />
              </React.Fragment>
            )
            : <React.Fragment />
        }
      </Section>

      <Section size={CONTAINER_WIDTH.XL4}>
        <ContactForm
          title="Regulatory Contact"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam scelerisque ac nisl eu sagittis. Ut sodales libero ut porttitor facilisis. Morbi faucibus dui eu elit pharetra."
        />
      </Section>

    </React.Fragment>
  );
};

export default Operations;
