import React from 'react';
import PropTypes from 'prop-types';
import DmsCoordinates from 'dms-conversion';
import { LngLat, LngLatLike } from 'mapbox-gl';

const arrow = (
  <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.3845 15.3285L7.18663 2.31338C7.02932 2.09097 6.62623 2.18403 6.58168 2.45304L4.02017 18.183C3.99873 18.3166 4.05894 18.4493 4.17328 18.5213L4.9774 19.0238C5.0948 19.0971 5.24471 19.0907 5.35501 19.0082L9.93611 15.6027L15.5464 16.6553C15.5925 16.6639 15.639 16.6628 15.6834 16.6526C15.7684 16.6329 15.8443 16.5803 15.8927 16.5038L16.3952 15.6997C16.4665 15.5855 16.4623 15.4392 16.3845 15.3285Z" fill="#242C2E" />
  </svg>
);

// @ts-ignore
const LegendCoords = ({ coords }: LngLatLike) => {
  const coordsValue = new DmsCoordinates(coords.lat, coords.lng);
  const coordsArray = coordsValue.dmsArrays;

  return (
    <div className="text-regular flex py-2 px-6 rounded-full bg-gray-200 justify-between">
      <div className="mr-8">{arrow}</div>
      <div className="w-64 text-right">{`${coordsArray.latitude[0]}° ${coordsArray.latitude[1]}' ${coordsArray.latitude[2].toFixed(2)}" ${coordsArray.latitude[3]} - ${coordsArray.longitude[0]}° ${coordsArray.longitude[1]}' ${coordsArray.longitude[2].toFixed(2)}" ${coordsArray.longitude[3]}`}</div>
    </div>
  );
};

LegendCoords.propTypes = {
  coords: PropTypes.instanceOf(LngLat).isRequired,
};

export default LegendCoords;
