import { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Switch } from 'react-router-dom';

import PageTitleContext from 'context/PageTitleContext';
import ROUTES from './routes';
import PublicRoute from './Route';

const Router = () => {
  const { pageTitle } = useContext(PageTitleContext);

  return (
    <BrowserRouter>
      <Helmet>
        <title>
          {`The Metals Company${pageTitle && ` | ${pageTitle}`}`}
        </title>
      </Helmet>
      <Switch>
        {ROUTES.map(({
          key,
          path,
          component,
          exact = true,
          layout,
          needsToken = true,
          noMenuOptions,
          className,
          footerMode,
          headerMode,
          maintenance,
        }) => (
          <PublicRoute
            key={key}
            exact={exact}
            path={path}
            component={component}
            className={className}
            footerMode={footerMode}
            headerMode={headerMode}
            layout={layout}
            needsToken={needsToken}
            noMenuOptions={noMenuOptions}
            maintenance={maintenance}
          />
        ))}
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
