import { Map } from 'mapbox-gl';

const toggleLayer = (map: Map, layerIds: string[], visible: boolean) => {
  for (let i = 0; i < layerIds.length; i += 1) {
    const layerId = layerIds[i];
    if (map.getLayer(layerId)) {
      map.setLayoutProperty(
        layerId,
        'visibility',
        visible ? 'visible' : 'none',
      );
    }
  }
};

export default toggleLayer;
