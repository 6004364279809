import React, { useEffect, useState } from 'react';
import { parse, ParsedQuery } from 'query-string';

import { IPropsWithChildren } from 'interfaces';
import TokenValidator from 'utils/TokenValidator';
import tokenStorage from 'utils/TokenStorage';

interface ISearchParams extends ParsedQuery {
  token: string;
}

interface TokenProviderValue {
  loading: boolean;
  isValidToken: boolean | null;
}

const providerDefaultValue: TokenProviderValue = {
  loading: true,
  isValidToken: false,
};

export const TokenContext = React.createContext(providerDefaultValue);

const TokenProvider = ({ children }: IPropsWithChildren) => {
  const [defaultValue, setDefaultValue] = useState<TokenProviderValue>(providerDefaultValue);

  useEffect(() => {
    const params = parse(window.location.search) as ISearchParams;
    const currentToken = tokenStorage.token;

    const validateToken = async () => {
      let isValidToken;

      try {
        isValidToken = await TokenValidator.validate(currentToken || params.token);
      } catch (e) {
        isValidToken = false;
      }

      const newDefaultValue = {
        loading: false,
        isValidToken: false,
      };

      if (isValidToken) {
        if (currentToken !== params.token && params.token) {
          tokenStorage.token = params.token;
        }
        newDefaultValue.isValidToken = true;
      } else {
        tokenStorage.token = '';
      }

      setDefaultValue(newDefaultValue);
    };

    validateToken().then(() => {});
  }, [setDefaultValue]);

  return (
    <TokenContext.Provider value={defaultValue}>
      {children}
    </TokenContext.Provider>
  );
};

export default TokenProvider;
