import { useContext, useEffect, useState } from 'react';
import { Map, GeoJSONSource } from 'mapbox-gl';
import { Position } from 'geojson';
import MapOperationsContext from 'context/MapOperationsContext';

import { IMapTicker, mapTicker } from 'utils/mapTicker';
import { CollectorAuraCircle, CollectorCircle, MinedMinePathLine } from '../Mapbox';
import toggleLayer from './utils';

interface CLiveProps {
  map: Map,
  collectorPoints: any[],
  mineData: Position[],
  id: string
}

const CLive = ({
  map, collectorPoints, mineData, id,
}: CLiveProps) => {
  const collectorSourceId = `${id}CollectorSource`;
  const collectorLayerId = `${id}CollectorLayer`;
  const collectorAuraLayerId = `${id}CollectorAuraLayer`;
  const minedPathSourceId = `${id}MinedPathSource`;
  const minedPathLayerId = `${id}MinedPathLayer`;
  const [counter, setCounter] = useState(0);

  const { showCollector, showMinedMinePath, setSimulatedTime } = useContext(MapOperationsContext);

  const getMinedData = (index = 0) => ({
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: mineData && mineData.slice(0, index),
        },
      },
    ],
  });

  useEffect(() => {
    if (!map) return;
    // Mined path.
    if (!map.getSource(minedPathSourceId)) {
      map.addSource(minedPathSourceId, {
        type: 'geojson',
        // @ts-ignore
        data: getMinedData(),
      });
      map.addLayer({
        id: minedPathLayerId,
        source: minedPathSourceId,
        type: 'line',
        paint: MinedMinePathLine,
      });
    }

    // Collector.
    if (!map.getSource(collectorSourceId)) {
      map.addSource(collectorSourceId, {
        type: 'geojson',
        // @ts-ignore
        data: collectorPoints[0],
      });
      map.addLayer({
        id: collectorAuraLayerId,
        source: collectorSourceId,
        type: 'circle',
        paint: CollectorAuraCircle,
      });
      map.addLayer({
        id: collectorLayerId,
        source: collectorSourceId,
        type: 'circle',
        paint: CollectorCircle,
      });
    }

    toggleLayer(map, [collectorLayerId, collectorAuraLayerId], showCollector);
    toggleLayer(map, [minedPathLayerId], showMinedMinePath);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCollector, showMinedMinePath]);

  useEffect(() => {
    if (!map) {
      return;
    }

    const updateCounter = (tick: IMapTicker) => {
      if (!map) {
        return;
      }

      const { current, currentDate } = tick;
      if (counter >= collectorPoints.length) return;
      if (map.getSource(minedPathSourceId)) {
        const minedPathSource = map.getSource(minedPathSourceId) as GeoJSONSource;
        minedPathSource.setData(
          // @ts-ignore
          getMinedData(counter),
        );
      }
      if (map.getSource(collectorSourceId)) {
        const collectorSource = map.getSource(collectorSourceId) as GeoJSONSource;
        collectorSource.setData(
          collectorPoints[counter],
        );
      }
      if (map.getSource(collectorAuraLayerId)) {
        const collectorAuraSource = map.getSource(collectorAuraLayerId) as GeoJSONSource;
        collectorAuraSource.setData(
          collectorPoints[counter],
        );
      }
      setCounter(current + 1);

      setSimulatedTime(`${currentDate.toLocaleString('default', { month: 'short' })}, ${currentDate.getDate()} ${currentDate.getFullYear()}`);
    };

    const subscription$ = mapTicker.subscribe((tick: IMapTicker) => {
      updateCounter(tick);
    });

    // eslint-disable-next-line consistent-return
    return () => subscription$.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, setCounter, setSimulatedTime]);

  return null;
};

export default CLive;
