/**
 * Save or get item from LS
 */
class TokenStorage {
  public static TOKEN_KEY = 'token';

  private readonly privateKey;

  private privateToken: string = '';

  constructor(key: string = '__tmc') {
    this.privateKey = key;
  }

  /**
   * Get token from LS
   */
  get token():string {
    if (!this.privateToken) {
      const keyName = this.key(TokenStorage.TOKEN_KEY);
      this.privateToken = TokenStorage.ls().getItem(keyName) || '';
    }

    return this.privateToken;
  }

  /**
   * Save token in LS
   * @param value
   */
  set token(value: string) {
    const keyName = this.key(TokenStorage.TOKEN_KEY);
    TokenStorage.ls().setItem(keyName, value);
  }

  /**
   * Make the key param to save or get data from LS
   * @param key
   */
  key(key:string):string {
    return `${this.privateKey}-${key}`;
  }

  /**
   * Local Storage object is it's available
   */
  static ls() {
    return window.localStorage || {
      setItem() {},
      getItem() {},
    };
  }
}

const tokenStorage = new TokenStorage();

export default tokenStorage;
