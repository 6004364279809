import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { IPropsWithClassName } from 'interfaces';

interface PublicRouteProps extends IPropsWithClassName {
  component: any;
  layout: any;
  path: string;
  exact?: boolean;
  headerMode?: string;
  footerMode?: string;
  needsToken?: boolean;
  noMenuOptions?: boolean;
  maintenance?: boolean;
}

const PublicRoute = ({
  component,
  layout: Layout,
  className,
  headerMode,
  footerMode,
  needsToken = true,
  noMenuOptions = false,
  maintenance = false,
  ...rest
}: PublicRouteProps) => (
  <Route
    {...rest}
    render={
      (routeProps) => (
        <Layout
          {...routeProps}
          className={className}
          headerMode={headerMode}
          footerMode={footerMode}
          component={component}
          needsToken={needsToken}
          noMenuOptions={noMenuOptions}
          maintenance={maintenance}
        />
      )
    }
  />
);

PublicRoute.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.func.isRequired,
  className: PropTypes.string,
  headerMode: PropTypes.string,
  footerMode: PropTypes.string,
  path: PropTypes.string,
  exact: PropTypes.bool,
  needsToken: PropTypes.bool,
  noMenuOptions: PropTypes.bool,
  maintenance: PropTypes.bool,
};

PublicRoute.defaultProps = {
  className: null,
  headerMode: null,
  footerMode: null,
  path: null,
  exact: null,
  needsToken: true,
  noMenuOptions: false,
  maintenance: false,
};

export default PublicRoute;
