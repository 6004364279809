import React from 'react';
import PropTypes from 'prop-types';

export interface ChartKeyItemProps {
  title: string;
  subtitle: string;
  description?: string | null;
  color?: string;
}

const ChartKeyItem = ({
  title,
  subtitle,
  description,
  color,
}: ChartKeyItemProps) => (
  <div>
    <div className="h-2 mb-3" style={{ backgroundColor: color }} />
    <div className="mb-3 text-blueGray">{title}</div>
    <div className="mb-3 text-gray-800 text-sm">{subtitle}</div>
    <div className="text-gray-800 text-sm">{description}</div>
  </div>
);

ChartKeyItem.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  color: PropTypes.string,
  description: PropTypes.string,
};

ChartKeyItem.defaultProps = {
  color: '#28B42E',
  description: null,
};

export default ChartKeyItem;
