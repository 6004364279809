import React, { useEffect, useState } from 'react';
import Vimeo from '../../embedVideo/Vimeo';

import pattern from '../images/pattern.svg';
import arrow from '../images/arrow-white.svg';
import '../map.scss';
import { indicators1, indicators2 } from '../CollectorIndicators.mock';

interface IndicatorCardProps {
  values: number[],
  interval: number,
  unit: string,
  description: string
}

interface IndicatorColItemProps {
  label: string,
  values: number[],
  unit: string,
  interval: number,
  border?: boolean;
}
interface IndicatorColProps {
  items: IndicatorColItemProps[]
}

interface ViewScreenProps {
  lat: string,
  lng: string,
}

const IndicatorCard = ({
  values, interval, unit, description,
}: IndicatorCardProps) => {
  const [val, setVal] = useState(values[0]);
  useEffect(() => {
    const intValue = setInterval(() => {
      const a = values[Math.floor(Math.random() * values.length)];
      setVal(a);
    }, interval);

    return () => {
      clearInterval(intValue);
    };
  });

  return (
    <div className="rounded-md bg-white p-3 mb-3">
      <div className="text-blueGray-700">
        <span className="text-4xl leading-none">{val.toFixed(2)}</span>
        <span className="text-2xl ml-3">{unit}</span>
      </div>
      <div className="text-sm text-gray-500">{description}</div>
    </div>
  );
};

const IndicatorColItem = ({
  label, values, unit, interval, border = true,
}: IndicatorColItemProps) => {
  const [val, setVal] = useState(values[0]);
  useEffect(() => {
    const intValue = setInterval(() => {
      setVal(values[Math.floor(Math.random() * values.length)]);
    }, interval);

    return () => {
      clearInterval(intValue);
    };
  });

  return (
    <div className={`flex text-sm py-3 px-5 ${border ? 'border-b' : ''}`}>
      <div className="w-1/2 text-sm text-gray-500">{label}</div>
      <div className="text-blue-500">{`${val.toFixed(2)} ${unit}`}</div>
    </div>
  );
};

IndicatorColItem.defaultProps = {
  border: true,
};

const IndicatorCol = ({ items }: IndicatorColProps) => (
  <div className="rounded-md bg-white">
    {items.map(({
      values, unit, label, interval,
    }, index) => (
      <IndicatorColItem
        key={label}
        values={values}
        label={label}
        unit={unit}
        interval={interval}
        border={index !== items.length - 1}
      />
    ))}
  </div>
);

const ViewScreen = ({ lat, lng }: ViewScreenProps) => (
  <div className="rounded-md bg-white p-3 flex flex-col flex-1 h-full mb-3">
    <div className="rounded-full bg-blueGray text-white py-2 px-4 mb-2 text-xs flex">
      <img src={arrow} alt="" />
      <span className="ml-7">{lat}</span>
      <span className="ml-auto pr-5">{lng}</span>
    </div>
    <div className="relative flex-1">
      <div className="h-full w-full flex items-center justify-center bg-view-screen" style={{ backgroundImage: `url(${pattern})` }}>
        <div className="collector" />
        <div className="mine-path" />
      </div>
    </div>
  </div>
);

const CModal = () => {
  const dummyLat = '9° 52\' 16.6908" N';
  const dummyLon = '-117° 30\' 30.467" W';

  return (
    <div className="h-full w-full relative">
      <div className="bg-video">
        <Vimeo videoId="564787727" />
      </div>
      <footer className="grid grid-cols-11 gap-4 p-6 absolute bottom-0 w-full">
        <div className="col-span-2">
          <IndicatorCard values={[17.1, 17.2, 17.3, 17.4, 17.5, 17.6, 17.7, 17.9]} interval={1000} unit="kg/m" description="Abundance" />
          <IndicatorCard values={[90.0, 90.1, 90.2, 90.3, 90.4, 90.5, 90.6, 90.7, 90.8]} interval={800} unit="%" description="Pickup Efficiency" />
        </div>
        <div className="col-span-2">
          <IndicatorCard values={[998.7, 998.8, 998.9]} interval={3000} unit="ton/hr" description="Tonnage In Last Hour" />
          <IndicatorCard values={[4.90, 4.91, 4.92, 4.93, 4.94, 4.95, 4.96, 4.97, 4.98, 4.99]} interval={1200} unit="%" description="Nodule Attrition Rate" />
        </div>

        <div className="col-span-3 h-full">
          <ViewScreen lat={dummyLat} lng={dummyLon} />
        </div>

        <div className="col-span-2">
          <IndicatorCol items={indicators1} />
        </div>

        <div className="col-span-2">
          <IndicatorCol items={indicators2} />
        </div>
      </footer>
    </div>
  );
};

export default CModal;
