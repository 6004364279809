import React, { useContext, Fragment } from 'react';
import ModalContext from 'context/ModalContext';
import { Dialog, Transition } from '@headlessui/react';

import './modal.scss';

const closeButton = (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="white" />
  </svg>
);

const Modal = () => {
  const {
    content, setOpen, open, title,
  } = useContext(ModalContext);

  document.onkeydown = (evt) => {
    let isEscape;
    if ('key' in evt) {
      isEscape = (evt.key === 'Escape' || evt.key === 'Esc');
    } else {
      isEscape = (evt.keyCode === 27);
    }
    if (isEscape) {
      setOpen(false);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" static className="fixed inset-0 overflow-hidden z-50" open={open} onClose={setOpen}>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-blueGray-900 bg-opacity-80 cursor-pointer transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-y-0 right-0 pl-10 max-w-full w-11/12 flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-300"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-300"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="relative w-screen">
                <div className="h-full flex flex-col bg-blueGray-900 shadow-xl overflow-y-scroll">
                  <div className="px-4 sm:px-6">
                    <Dialog.Title className="py-4 px-6 flex">
                      <div className="text-xl font-medium text-white text-center flex-1">{title}</div>
                      <button
                        type="button"
                        className="border-0 text-white focus:outline-none"
                        onClick={() => setOpen(false)}
                      >
                        {closeButton}
                      </button>
                    </Dialog.Title>
                  </div>
                  <div className="relative flex-1 max-h-full overflow-y-hidden">
                    {content}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
