import {
  select,
  path,
  linkHorizontal,
  Selection,
} from 'd3';
import _map from 'lodash/map';
import _forEach from 'lodash/forEach';
import { IChartSankeyDataIndicators, IEcosystemServicesDataPeriod, IIndicatorsLevels } from 'interfaces';
import {
  COLORS,
  INDICATOR_LINK_WIDTH,
  INDICATOR_NODE_RADIUS,
  LINES_AREA_HEIGHT,
} from './config';

const NO_USER_INTERACTIONS_CLASSES = 'pointer-events-none select-none';

export const selectElement = (selector: string) => select(selector);
/**
 * Created the ruler at bottom
 * @param svg
 * @param data
 * @param width
 * @param yPosition
 * @param marginLeft
 */
export const makeXAxis = (
  svg: Selection<SVGGElement, unknown, null, undefined>,
  data: IEcosystemServicesDataPeriod[],
  width: number,
  yPosition: number,
  marginLeft = 0,
) => {
  const xAxisGroup = svg.append('g').attr('class', 'xaxis');
  if (data.length) {
    const length = data.length - 1;
    const half = length / 2;
    const margin = Math.ceil((width - (marginLeft * 2)) / length);
    const lineHeight = 10;

    /* Horizontal lines */
    /* Top */
    xAxisGroup.append('line')
      .attr('x2', width)
      .style('stroke-width', 1)
      .style('stroke', COLORS.LINE_SEPARATOR);

    /* Bottom */
    xAxisGroup.append('line')
      .attr('x2', width)
      .attr('y1', yPosition)
      .attr('y2', yPosition)
      .style('stroke-width', 0.5)
      .style('stroke', COLORS.LINE_SEPARATOR);

    data.forEach((item, index) => {
      const { title, isPresent } = item;
      const className = isPresent ? 'font-medium present' : '';
      const xTranslate = Math.ceil(margin * index) + marginLeft;
      const isHalf = index === half;

      const group = xAxisGroup.append('g')
        .attr('transform', `translate(${xTranslate}, ${yPosition})`);

      const line = group.append('line')
        .attr('y2', lineHeight)
        .style('stroke-width', 1)
        .style('stroke', COLORS.SEPARATOR);

      if (isHalf) {
        line
          .attr('y0', 0)
          .attr('y2', yPosition + lineHeight)
          .attr('transform', `translate(0, -${yPosition})`)
          .style('stroke', COLORS.HALF_SEPARATOR);
      }
      if (length !== index) {
        const halfMargin = Math.ceil(margin / 2);
        group.append('line')
          .attr('x1', halfMargin)
          .attr('y2', lineHeight)
          .attr('x2', halfMargin)
          .style('stroke-width', 1)
          .style('stroke', COLORS.SEPARATOR);
      }
      group.append('text')
        .text(isHalf ? 'Present' : title)
        .attr('class', `text-xxs ${className}`)
        .attr('text-anchor', 'middle')
        .attr('transform', 'translate(0, 25)')
        .style('color', COLORS.TEXTS);
    });
  }

  return xAxisGroup;
};

export const makeIndicators = (
  svg: Selection<SVGGElement, unknown, null, undefined>,
  periods: IEcosystemServicesDataPeriod[],
  indicators: IChartSankeyDataIndicators[],
  width: number,
  yPosition: number,
  marginLeft = 0,
  beforeActive: boolean = true,
) => {
  const dataGroup = svg.append('g')
    .attr('class', `indicators ${NO_USER_INTERACTIONS_CLASSES}`)
    .attr('transform', `translate(0, ${yPosition + INDICATOR_NODE_RADIUS})`);
  const length = periods.length - 1;
  const margin = Math.ceil((width - (marginLeft * 2)) / length);
  const dataPosition = _map(periods, (period, index) => Math.ceil(margin * index) + marginLeft);

  const makeLink = (source:[number, number], target: [number, number]) => linkHorizontal()({
    source,
    target,
  });

  const halfIndicatorsArea = LINES_AREA_HEIGHT / 2;
  const halfRadio = INDICATOR_NODE_RADIUS / 2;
  _forEach(indicators, ({ levels, name = '', active }) => {
    const { before = [], prediction = [] } = levels || {};
    const beforeLength = before.length - 1;
    const mergedLevels = [...before, ...prediction];
    const opacity = active ? 1 : 0.15;
    const nodes:any = [];

    const indicatorGroup = dataGroup.append('g').attr('title', name);
    _forEach(mergedLevels, (levelItem, levelIndicatorIndex) => {
      _forEach(levelItem, ({ value, weight }) => {
        const xPosition = dataPosition[levelIndicatorIndex] + (margin * weight);
        let yLevelPosition = -1 * (value * halfIndicatorsArea - halfRadio);
        if (Math.abs(value) > 1) {
          yLevelPosition = -1 * (halfIndicatorsArea + halfRadio + ((value - 1) * 25));
        }
        const node = indicatorGroup.append('circle')
          .attr('r', 0)
          .style('fill', COLORS.INDICATOR_NODE)
          .attr('transform', `translate(${xPosition}, ${yLevelPosition})`)
          .style('opacity', 0);

        const transition = node.transition()
          .duration(200)
          .delay(80 * levelIndicatorIndex)
          .attr('r', INDICATOR_NODE_RADIUS);
        const isBefore = levelIndicatorIndex < beforeLength;
        if (beforeActive && isBefore) {
          transition.style('opacity', 1);
        } else {
          transition.style('opacity', opacity);
        }
        const source = [xPosition - halfRadio, yLevelPosition];
        nodes.push({ data: source, isBefore });
      });
    });

    const linksGroup = dataGroup.append('g');
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < nodes.length - 1; i++) {
      const nodeSource = nodes[i];
      const nodeTarget = nodes[i + 1];
      let firstLinkNode;

      const firstLink = i === 0
        ? makeLink([nodeSource.data[0] - margin, nodeSource.data[1]], nodeSource.data)
        : null;
      const makeLinkPath = (link: string | null) => linksGroup.append('path')
        .attr('d', link || '')
        .attr('stroke', COLORS.INDICATOR_NODE)
        .attr('fill', 'none')
        .style('opacity', opacity)
        .style('stroke-dasharray', 1000)
        .style('stroke-dashoffset', 1000)
        .style('stroke-width', INDICATOR_LINK_WIDTH);

      if (firstLink) {
        firstLinkNode = makeLinkPath(firstLink);
      }

      const link = makeLink(nodeSource.data, nodeTarget.data);
      const linkNode = makeLinkPath(link);

      if (beforeActive && nodeSource.isBefore && nodeTarget.isBefore) {
        if (linkNode) {
          linkNode.style('opacity', 1);
        }
        if (firstLinkNode) {
          firstLinkNode.style('opacity', 1);
        }
      }

      if (firstLinkNode) {
        firstLinkNode.style('stroke-dasharray', '0')
          .style('stroke-dashoffset', '0');
      }

      if (linkNode) {
        linkNode.transition()
          .duration(400)
          .delay(80 * i)
          .style('stroke-dashoffset', '0');
      }
    }
  });

  return dataGroup;
};

/**
 * Make the horizontal levels
 * @param svg
 * @param data
 * @param width
 * @param yInitial
 * @param reverse
 * @param barHeight
 */
export const makeLevels = (
  svg: Selection<SVGGElement, unknown, null, undefined>,
  data: IIndicatorsLevels[],
  width: number,
  yInitial: number = 0,
  reverse = false,
  barHeight = 25,
) => {
  const TEXT_MISMATCH = 5;
  const levelsGroup = svg.append('g')
    .attr('class', NO_USER_INTERACTIONS_CLASSES)
    .attr('transform', `translate(0, ${yInitial})`);
  const levelsTextGroup = svg.append('g')
    .attr('class', 'levels')
    .attr('transform', `translate(0, ${yInitial + barHeight + TEXT_MISMATCH})`);

  if (data.length) {
    const levels = [...data];
    if (reverse) {
      levels.reverse();
    }
    levels.forEach((level, index) => {
      const { name, color } = level;
      const levelClassSuffix = reverse ? 3 - index : index + 1;

      levelsGroup.append('rect')
        .attr('width', width)
        .attr('height', barHeight)
        .attr('transform', `translate(0, ${barHeight * index})`)
        .style('fill', color);

      const text = levelsTextGroup.append('text')
        .text(name)
        .attr('class', `text-xxs level level-${levelClassSuffix}`)
        .attr('alignment-baseline', 'middle')
        .attr('dominant-baseline', 'middle')
        .attr('transform', `translate(0, ${barHeight * (index)})`)
        .style('color', COLORS.TEXTS);
      const {
        width: textWidth = 0,
        height: textHeight = 0,
      } = text.node()?.getBoundingClientRect() || {};
      text.attr('x', width - 6 - textWidth)
        .attr('y', -1 * (barHeight - textHeight * 0.5));
    });

    levels.forEach((level, index) => {
      const { lineColor } = level;
      const linePosition = reverse ? barHeight * (index + 1) : barHeight * index;
      levelsGroup.append('line')
        .attr('transform', `translate(0, ${linePosition})`)
        .attr('x1', 0)
        .attr('x2', width)
        .style('stroke', lineColor)
        .style('stroke-width', 1)
        .style('stroke-dasharray', '2, 2');
    });
  }
  return levelsGroup;
};

/**
 * Creates the diagram title
 * @param svg
 * @param diagramWidth
 * @param text
 * @param arrow
 */
export const makeTitle = (
  svg: Selection<SVGGElement, unknown, null, undefined>,
  diagramWidth: number,
  text: string,
  arrow: boolean = true,
) => {
  const extraMargin = 5;
  const xPosition = Math.ceil(diagramWidth / 2) + extraMargin;
  const group = svg.append('g').attr('transform', `translate(${xPosition} ,0)`);
  const textNode = group.append('text')
    .text(text)
    .attr('x', 0)
    .attr('y', 0)
    .attr('class', 'text-xxs font-medium present')
    .style('color', COLORS.TEXTS);
  const {
    width: titleWidth = 0,
    height: titleHeight = 0,
  } = textNode.node()?.getBoundingClientRect() || {};
  group.attr('transform', `translate(${xPosition + titleWidth / 2} , -5)`);

  if (arrow) {
    const arrowContext = path();
    const top = 14;
    const xInitial = titleWidth - 15;
    const yInitial = Math.ceil(top + titleHeight / 2);
    const arrowAngle = 4;
    arrowContext.moveTo(xInitial, yInitial);
    arrowContext.lineTo(xInitial + 10, yInitial);
    arrowContext.moveTo(xInitial + 6, yInitial - arrowAngle);
    arrowContext.lineTo(xInitial + 10, yInitial);
    arrowContext.moveTo(xInitial + 6, yInitial + arrowAngle);
    arrowContext.lineTo(xInitial + 10, yInitial);

    group.append('path')
      .attr('d', arrowContext.toString())
      .style('stroke-width', 0.5)
      .style('stroke', 'black');
  }
};
