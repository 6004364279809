import React, { MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { IPropsWithClassName } from 'interfaces';

const DEFAULT_ICON = (
  <svg width="20" height="20" viewBox="10 10 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 29H27V27H13V29ZM27 18H23V12H17V18H13L20 25L27 18Z" fill="currentColor" />
  </svg>
);

const buttonClasses = {
  primary: [
    'bg-blueGray-700',
    'text-white',
    'hover:bg-blueGray-800',
    'focus:bg-blueGray-800',
    'active:bg-blueGray-900',
  ],
  secondary: [
    'bg-gray',
    'text-black',
    'hover:bg-gray-dark',
    'focus:bg-gray-dark',
    'active:bg-gray-darkest',
  ],
  outline: [
    'bg-white',
    'text-blue-500',
    'border-2',
    'border-blue-500',
    'hover:bg-blue-500',
    'hover:text-white',
    'focus:bg-blue-500',
    'focus:text-white',
    'active:bg-blue-900',
    'active:border-blue-900',
  ],
};

export interface IconButtonProps extends IPropsWithClassName{
  title: string;
  subtitle: string;
  to: string;
  icon: any;
  type: keyof typeof buttonClasses;
  block?: boolean;
  onClick?(e: MouseEvent): void;
}

const IconButton = ({
  title,
  subtitle,
  to,
  icon,
  type,
  className,
  block = false,
  onClick,
}: IconButtonProps) => {
  const extraClasses = buttonClasses[type];

  const classes = classNames(
    'pt-5 pb-4',
    block ? 'flex' : 'inline-flex',
    'font-medium tracking-wide leading-none',
    'focus-visible:ring-4',
    'focus-visible:ring-amber-400',
    'disabled:opacity-50',
    'disabled:pointer-events-none',
    extraClasses,
    className,
  );

  const onButtonClick = (e:MouseEvent) => {
    if (onClick && typeof onClick === 'function') {
      onClick(e);
    }
  };

  return (
    <Link
      to={to}
      className={classes}
      title={title}
      onClick={(e) => onButtonClick(e)}
    >
      <span className="px-3">
        {icon}
      </span>
      <span className="pr-8">
        <span className="block mb-1">{title}</span>
        <span className="text-xs">{subtitle}</span>
      </span>
    </Link>
  );
};

IconButton.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  icon: PropTypes.object,
  type: PropTypes.oneOf(['primary', 'secondary', 'outline']),
  className: PropTypes.string,
  block: PropTypes.bool,
  onClick: PropTypes.func,
};

IconButton.defaultProps = {
  icon: DEFAULT_ICON,
  type: 'primary',
  className: null,
  block: false,
  onClick: null,
};

export default IconButton;
