import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { IPropsWithChildren, IPropsWithClassName } from 'interfaces';
import { ALIGNMENTS, CONTAINER_WIDTH } from 'enums';

export interface SectionHeaderProps extends IPropsWithClassName, IPropsWithChildren {
  title?: string;
  alignment?: ALIGNMENTS,
  size?: CONTAINER_WIDTH,
}

const SectionHeader = ({
  title,
  className,
  children,
  alignment,
  size = CONTAINER_WIDTH.XL4,
}: SectionHeaderProps) => {
  const componentClasses = classNames(
    `text-${alignment}`,
    'mb-10',
    'dark:text-white',
    className,
    size,
    {
      'mx-auto': size,
    },
  );

  return (
    <article className={componentClasses}>
      { title && <h2 className="text-3xl font-medium">{ title }</h2>}
      <div className="mt-6 text-lg text-gray-800 dark:text-gray-300">
        { children }
      </div>
    </article>
  );
};

SectionHeader.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.string,
  ]),
  alignment: PropTypes.string,
  size: PropTypes.string,
};

SectionHeader.defaultProps = {
  className: null,
  title: null,
  alignment: ALIGNMENTS.CENTER,
  children: null,
  size: CONTAINER_WIDTH.XL4,
};

export default SectionHeader;
