import React, { useMemo, useState } from 'react';

import { IPropsWithChildren, ModalProps } from 'interfaces';
import ModalContext from './ModalContext';

interface ModalContextProviderProps extends IPropsWithChildren {
}

const ModalContextProvider = ({ children }: ModalContextProviderProps) => {
  const [openValue, setOpenValue] = useState(false);
  const [titleValue, setTitleValue] = useState('');
  const [contentValue, setContentValue] = useState(null);

  const modalContextValue = useMemo(() => ({
    open: openValue,
    title: titleValue,
    content: contentValue as unknown as ModalProps,
    setOpen: (value: boolean) => {
      const body = document.querySelector('body');
      // @ts-ignore
      body.classList.toggle('modal-active');
      setOpenValue(value);
    },
    setTitle: (value: string) => setTitleValue(value),
    setContent: (value: any) => setContentValue(value),
  }), [openValue, titleValue, contentValue]);

  return (
    // @ts-ignore
    <ModalContext.Provider value={modalContextValue}>
      {children}
    </ModalContext.Provider>
  );
};

ModalContextProvider.propTypes = {

};

export default ModalContextProvider;
