import DefaultLayout from 'layouts';

import HomeIndex from 'pages/index';
import Home from 'pages/home';
import Environment from 'pages/environment';
import Operations from 'pages/operations';
import Maintenance from 'pages/maintenance';
import NotFound from 'pages/not-found';

import pathNames from 'router/path-names';

export interface IRoute {
  key: string;
  path: string;
  layout: any;
  component: any;
  className?: string;
  footerMode?: string;
  headerMode?: string;
  exact?: boolean;
  needsToken?: boolean;
  noMenuOptions?: boolean;
  maintenance?: boolean;
}

const ROUTES:IRoute[] = [
  {
    key: 'home',
    path: pathNames.home,
    component: Home,
    layout: DefaultLayout,
  },
  {
    key: 'index',
    path: pathNames.index,
    footerMode: 'mt-auto',
    component: HomeIndex,
    layout: DefaultLayout,
    needsToken: false,
    noMenuOptions: true,
  },
  {
    key: 'environment',
    path: pathNames.environment,
    component: Environment,
    layout: DefaultLayout,
  },
  {
    key: 'operations',
    path: pathNames.operations,
    component: Operations,
    layout: DefaultLayout,
  },
  {
    key: 'maintenance',
    path: pathNames.maintenance,
    component: Maintenance,
    layout: DefaultLayout,
    needsToken: false,
    noMenuOptions: true,
    maintenance: true,
  },
  {
    key: 'not-found',
    path: '*',
    needsToken: false,
    component: NotFound,
    layout: DefaultLayout,
  },
];

export default ROUTES;
