import {
  interval, map, Subject, switchMap, takeWhile, startWith,
} from 'rxjs';

export interface IMapTicker {
  current: number;
  currentDate: Date;
}

/**
 * Total points: 4414 * 5 = 20.070
 */
export const TOTAL_MAP_POINTS = (4414 * 5);
export const POINTS_PER_DAY = 150;
export const INTERVAL_TIME = 25;
export const startDate = Date.parse('2024-06-01');

const mapTicker = new Subject<IMapTicker>();
const resetTickerSubject = new Subject<void>();
const ticker$ = resetTickerSubject.pipe(
  startWith({}),
  switchMap(() => interval(INTERVAL_TIME)),
  takeWhile((current: number) => {
    const isActive = current < TOTAL_MAP_POINTS;
    if (!isActive) {
      resetTickerSubject.next();
      return true;
    }
    return isActive;
  }),
  map((current: number): IMapTicker => {
    const lengthPerDate = TOTAL_MAP_POINTS / POINTS_PER_DAY;
    const diffDate = Math.ceil(current / lengthPerDate);
    const currentDate = new Date(startDate.valueOf() + diffDate * 24 * 60 * 60 * 1000);

    return {
      current,
      currentDate,
    };
  }),
);

const tickerSubscription$ = ticker$.subscribe((currentTickerState) => {
  mapTicker.next(currentTickerState);
});

const restartTicker = () => {
  resetTickerSubject.next();
};

export {
  mapTicker,
  tickerSubscription$,
  restartTicker,
};
