// eslint-disable-next-line @typescript-eslint/naming-convention
export enum SANKEY_CLICK_TYPE {
  SERVICE = 'service',
  FUNCTION = 'function',
  INDICATOR = 'indicator',
}

export enum ALIGNMENTS {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum CONTAINER_WIDTH {
  XL4 = 'max-w-4xl',
  XL7 = 'max-w-7xl',
  HEADER = 'max-w-full ml-8 mr-8',
}

export enum NotificationStatus {
  RESOLVED = 'RESOLVED',
  IN_REVIEW = 'IN REVIEW',
  IN_PROGRESS = 'IN PROGRESS',
  EVENT_DETECTED = 'EVENT DETECTED',
}
