import React from 'react';
import './legend.scss';

const LegendDepth = () => (
  <div className="flex bg-gray-200 px-5 py-2 rounded-full text-blueGray-700 items-center">
    <div className="text-sm">Depth</div>
    <div className="flex flex-col ml-3 text-xs">
      <div className="flex justify-between mb-2">
        <div>Low -4500 m</div>
        <div>Hight -4100 m</div>
      </div>
      <div className="depth-spectrum" />
    </div>
  </div>
);

export default LegendDepth;
