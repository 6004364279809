import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { IPropsWithClassName } from 'interfaces';
import SectionHeader from 'components/section/SectionHeader';
import Button from 'components/button/button';

import './ContactForm.css';

export interface ContactFormProps extends IPropsWithClassName {
  title?: string;
  description?: string;
}

const ContactForm = ({ title, description, className }: ContactFormProps) => {
  const headerElement = (title || description)
    && <SectionHeader title={title}>{description}</SectionHeader>;
  const componentClasses = classNames(
    'contact-form',
    className,
  );
  const labelClasses = classNames(
    'text-sm',
    'text-black',
  );
  const formControlClassesObject = [
    'text-base',
    'text-black',
    'placeholder-gray-400',
    'py-3',
    'bg-gray-50',
    'w-full',
  ];
  const formControlClasses = classNames(
    ...formControlClassesObject,
    'px-3',
  );

  const selectClasses = classNames(
    ...formControlClassesObject,
    'px-0',
  );
  return (
    <section className={componentClasses}>
      {headerElement}
      <article className="mt-3 mb-12 grid grid-cols-1 md:grid-cols-2 gap-x-6">
        <div className="mb-5">
          <label className={labelClasses} htmlFor="attention-form-input">Attention</label>
          <div className="mt-2 bg-gray-50 px-2">
            <select className={selectClasses} id="attention-form-input">
              <option value="">Who do you want to contact?</option>
              <option value="1">Option 1</option>
              <option value="2">Option 2</option>
              <option value="3">Option 3</option>
            </select>
          </div>
        </div>
        <div className="mb-5">
          <label className={labelClasses} htmlFor="subject-form-input">Subject</label>
          <div className="mt-2 bg-gray-50 px-2">
            <select className={selectClasses} id="subject-form-input">
              <option value="">What’s the nature of your question?</option>
              <option value="1">Option 1</option>
              <option value="2">Option 2</option>
              <option value="3">Option 3</option>
            </select>
          </div>
        </div>
        <div className="md:col-span-2 mb-5">
          <label className={labelClasses} htmlFor="from-form-input">From</label>
          <div className="mt-2">
            <input className={formControlClasses} type="text" id="from-form-input" placeholder="What’s your name?" />
          </div>
        </div>
        <div className="mb-5">
          <label className={labelClasses} htmlFor="email-form-input">Email</label>
          <div className="mt-2">
            <input className={formControlClasses} type="email" id="email-form-input" placeholder="Enter Email" />
          </div>
        </div>
        <div className="mb-5">
          <label className={labelClasses} htmlFor="organization-form-input">Organization</label>
          <div className="mt-2">
            <input
              className={formControlClasses}
              type="text"
              id="organization-form-input"
              placeholder="Enter Organization"
            />
          </div>
        </div>
        <div className="md:col-span-2 mb-5">
          <label className={labelClasses} htmlFor="message-form-input">Message</label>
          <div className="mt-2">
            <textarea className={`${formControlClasses} h-24`} id="message-form-input" placeholder="Enter Message" />
          </div>
        </div>
        <div className="md:col-span-2 mt-2">
          <Button title="Send" to="#" />
        </div>
      </article>
    </section>
  );
};

ContactForm.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string,
};

ContactForm.defaultProps = {
  title: '',
  description: '',
  className: '',
};

export default ContactForm;
