import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { IPropsWithChildren, IPropsWithClassName } from 'interfaces';
import { CONTAINER_WIDTH } from 'enums';

export interface ContainerProps extends IPropsWithClassName, IPropsWithChildren {
  size?: CONTAINER_WIDTH;
}

const Container = ({
  className,
  children,
  size,
}: ContainerProps) => {
  const componentClasses = classNames(
    size,
    'mx-auto',
    'px-4 sm:px-6 xl:px-0',
    className,
  );

  return (
    <section className={componentClasses}>
      { children }
    </section>
  );
};

Container.propTypes = {
  size: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

Container.defaultProps = {
  className: null,
  size: CONTAINER_WIDTH.XL4,
  children: null,
};

export default Container;
