/* eslint-disable max-len */
import React, { useEffect } from 'react';

import Hero from 'components/hero/hero';
import Section, { BG_COLOR } from 'components/section/Section';
import SectionHeader from 'components/section/SectionHeader';
import { ALIGNMENTS, CONTAINER_WIDTH } from 'enums';
import ChartKey from 'components/chart_key/chartKey';
import EcosystemServices from 'components/ecosystem_service/EcosystemServices';

import diagramImg from 'images/diagram.svg';
import PageTitleContext from 'context/PageTitleContext';

const chartKeys = [
  { title: 'Baseline', color: '#28B42E', subtitle: 'Acceptable ranges as defined by the ESIA studies' },
  {
    title: 'Level 1',
    color: '#8AE93F',
    subtitle: 'Triggered when an ecosystem indicator is nearing a threshold',
    description: 'Increased monitoring',
  },
  {
    title: 'Level 2',
    color: '#FFCC00',
    subtitle: 'Triggered when an ecosystem indicator exits the acceptable range of values',
    description: 'Take operational actions which are predicted to bring the value back within range',
  },
  {
    title: 'Level 3',
    color: '#FC5569',
    subtitle: 'Serious Harm is predicted to occur',
    description: 'Take immediate and extreme action to prevent serious harm from occurring',
  },
];

const Environment = () => {
  const { setPageTitle } = React.useContext(PageTitleContext);

  useEffect(() => {
    setPageTitle('Environment');
  }, [setPageTitle]);

  return (
    <React.Fragment>
      <Hero
        title="Environment"
        size={CONTAINER_WIDTH.XL4}
        lead="This dataset provides active environmental monitoring of operational impacts on the marine environment, measuring our performance against a specfic set of ecosystemic function parameters determined by the ISA."
      />
      <Section bgColor={BG_COLOR.LIGHT_GRAY}>
        <div className="grid gap-6 grid-cols-6">
          <div className="col-span-4">
            <SectionHeader title="Ecosystem-Based Management" alignment={ALIGNMENTS.LEFT}>
              <p className="mb-6">
                DeepGreen’s principal objective in adopting an Ecosystem-Based Management (EBM) approach is to balance
                ecological, social and governance principles on appropriate temporal and spatial scales to achieve
                sustainable use of the polymetallic nodule resource.
              </p>
              <p className="mb-6">
                A comprehensive Environmental & Social Impact Assessment (ESIA) is currently underway which will
                characterize key components of the environmental logic.
              </p>
              <p>
                The data from the ESIA studies allows analysts to determine which indicators must be tracked, how they
                relate to key ecosystem services, and the causal relation between actions and impacts.
              </p>
            </SectionHeader>
          </div>
          <div className="col-span-2">
            <img src={diagramImg} alt="Ecosystem-Based Management diagram" />
          </div>
        </div>
      </Section>
      <Section size={CONTAINER_WIDTH.XL4}>
        <SectionHeader title="Ecosystem Services">
          <p className="mb-6">
            Ecosystem-Based Management is centered on sustaining and conserving the ecosystem services and functions
            which humans utilize. Since services cannot be directly measured, ecosystem indicators are used as proxy
            parameters which allow the system health to be calculated.
          </p>
          <p className="mb-6">
            Each ecosystem service is characterized by a number of ecosystem functions which in turn are
            measured-by-proxy using a set of ecosystem indicators for each function.
          </p>
          <p className="mb-6">
            The health of the service is a weighted average of its indicators depending on their relative level of
            influence. Specific indicators are deemed healthy if its value lies within an acceptable range as defined by
            threshold values. These ranges and thresholds are one outcome of the DeepGreen ESIA studies.
          </p>
        </SectionHeader>
      </Section>
      <Section size={CONTAINER_WIDTH.XL4} bgColor={BG_COLOR.LIGHT_GRAY} className="py-8" noPadding>
        <ChartKey items={chartKeys} />
      </Section>
      <Section>
        <EcosystemServices />
        <p className="text-xxs text-black mt-3 ml-4">
          * Ecosystem services and indicator health are
          for visual reference only and not actual management plan.
        </p>
      </Section>
    </React.Fragment>
  );
};

export default Environment;
