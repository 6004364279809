import React from 'react';
import classNames from 'classnames';

import Container from 'components/section/Container';
import NavBar from 'components/navbar/navbar';
import { CONTAINER_WIDTH } from 'enums';
import { IPropsWithClassName } from 'interfaces';

export interface FooterProps extends IPropsWithClassName {
  hideLinks: boolean;
}

const Footer = ({ className, hideLinks = false }: FooterProps) => {
  const now = new Date();
  const year = now.getFullYear();

  const componentClasses = classNames(
    'relative',
    'bg-amber-400',
    'mt-auto',
    className,
  );

  return (
    <footer className={componentClasses}>
      <Container size={CONTAINER_WIDTH.HEADER} className="py-6">
        <div className="flex justify-between items-center">
          <div className="font-regular text-blueGray-900">
            {`The Metals Company ©${year}`}
          </div>
          {!hideLinks && <NavBar />}
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
