import React, { useRef, useEffect, useState } from 'react';
import {
  Map, NavigationControl, LngLatBoundsLike, ScaleControl,
} from 'mapbox-gl';
import '../../../node_modules/mapbox-gl/dist/mapbox-gl.css';
import axios from 'axios';
import * as Turf from '@turf/turf';
import { Position } from 'geojson';

import { MAPBOX_ACCESS_KEY } from 'config';
import { NotificationsService } from 'utils/NotificationsService';
import { INotificationItem } from 'interfaces';
import { Styles, DEFAULT_ZOOM, DEFAULT_CENTER } from './Mapbox';

import O from './layers/O';
import BC from './layers/BC';
import MB from './layers/MB';
import MP from './layers/MP';
import SM from './layers/SM';
import CLive from './layers/CLive';

import Modal from '../modal/Modal';
import NotificationsOnMap from './layers/NotificationsOnMap';

interface MapOverlayProps {
  url: string,
  coordinates: number[][]
}

interface MapOperationsProps {
  mapBounds: LngLatBoundsLike,
  endpointBoxCores: string,
  endpointSeaMounts: string,
  endpointMineBox: string,
  endpointMinePath: string,
  backgroundSource: MapOverlayProps | null,
  onDrag(e: any): void
}

const MapOperations = (props: MapOperationsProps) => {
  const {
    mapBounds,
    endpointBoxCores,
    endpointSeaMounts,
    endpointMineBox,
    endpointMinePath,
    backgroundSource,
    onDrag,
  } = props;

  const [mapObject, setMapObject] = useState(null);
  const [collectorPoints, setCollectorPoints] = useState<any | null>(null);
  const [mineData, setMineData] = useState<Position[] | null>(null);
  const [notifications, setNotifications] = useState<INotificationItem[]>([]);
  const mapContainer = useRef<HTMLElement>();

  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();

    axios.get(endpointMinePath, {
      cancelToken: source.token,
    })
      .then((res) => {
        // Slide the mine path into chunk of 2kms each.
        // The mine path is 4414km.
        // const chunk = Turf.lineChunk(res.data, 2, { units: 'kilometers' });
        const line = Turf.lineString(res.data.features[0].geometry.coordinates);
        const points = [];
        const coords = [];
        for (let i = 0; i < 4414 * 5; i += 1) {
          const point = Turf.along(line, i / 10);
          points.push(point);
          coords.push(point.geometry.coordinates);
        }
        setCollectorPoints(points);
        setMineData(coords);
      })
      .catch(() => {});

    // create the map and configure it
    // check out the API reference for more options
    // https://docs.mapbox.com/mapbox-gl-js/api/map/
    const map = new Map({
      accessToken: MAPBOX_ACCESS_KEY,
      // @ts-ignore
      container: mapContainer.current,
      style: Styles.SATELLITE,
      center: DEFAULT_CENTER as [number, number],
      zoom: DEFAULT_ZOOM,
      maxBounds: mapBounds,
      attributionControl: false,
    });

    // Add zoom control.
    map.addControl(
      new NavigationControl({ showCompass: false }),
    );

    // Add scale control
    map.addControl(
      new ScaleControl({ maxWidth: 80, unit: 'metric' }),
    );

    // only want to work with the map after it has fully loaded
    // if you try to add sources and layers before the map has loaded
    // things will not work properly
    map.on('load', (e) => {
      // Trigger on drag event when the map fully loaded.
      onDrag(e);
    });

    // Attach onDrag event.
    map.on('drag', onDrag);

    // On map ready
    map.on('idle', () => {
      // Set map to context so that the layers component can use it.
      // @ts-ignore
      setMapObject(map);
    });

    map.scrollZoom.disable();
    map.on('wheel', (event) => {
      if (event.originalEvent.ctrlKey) {
        event.originalEvent.preventDefault();
        // @ts-ignore
        // eslint-disable-next-line no-underscore-dangle
        if (!map.scrollZoom._enabled) {
          map.scrollZoom.enable();
        }
        // @ts-ignore
        // eslint-disable-next-line no-underscore-dangle
      } else if (map.scrollZoom._enabled) {
        map.scrollZoom.disable();
      }
    });

    // cleanup function to remove map on unmount
    return () => {
      map.remove();
      source.cancel('Refreshing map');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Listen for Notifications */
  useEffect(() => {
    const subscription$ = NotificationsService.subscribe(
      (newNotifications: INotificationItem[]) => {
        setNotifications(newNotifications);
      },
    );

    return () => subscription$.unsubscribe();
  }, []);

  return (
    <React.Fragment>
      {/* @ts-ignore */}
      <div ref={mapContainer} style={{ width: '100%', height: '550px' }} />
      {mapObject && (
        <React.Fragment>
          {/* @ts-ignore */}
          <NotificationsOnMap map={mapObject} items={notifications} />
          {/* @ts-ignore */}
          <O map={mapObject} id="Overlay" config={backgroundSource} />
          {/* @ts-ignore */}
          <SM map={mapObject} id="SeaMounts" endpoint={endpointSeaMounts} />
          {/* @ts-ignore */}
          <MB map={mapObject} id="MineBox" endpoint={endpointMineBox} />
          {/* @ts-ignore */}
          <MP map={mapObject} id="MinePath" endpoint={endpointMinePath} />
          {/* @ts-ignore */}
          <BC map={mapObject} id="BoxCores" endpoint={endpointBoxCores} />
          {collectorPoints
            && (
              <CLive
                // @ts-ignore
                map={mapObject}
                id="Collector"
                collectorPoints={collectorPoints}
                // @ts-ignore
                mineData={mineData}
              />
            )}
        </React.Fragment>
      )}
      <Modal />
    </React.Fragment>
  );
};

export default MapOperations;
