import React, { useContext } from 'react';
import MapOperationsContext from '../../context/MapOperationsContext';

const LegendSimulatedTime = () => {
  const { simulatedTime } = useContext(MapOperationsContext);
  return (
    <div className="flex bg-gray-200 px-5 py-2 rounded-full text-blueGray-700 items-center">
      <div className="text-sm">Simulated Time</div>
      <div className="flex flex-col ml-3 text-sm text-gray-400">
        <div>{simulatedTime}</div>
      </div>
    </div>
  );
};

LegendSimulatedTime.propTypes = {

};

export default LegendSimulatedTime;
