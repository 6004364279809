import { useEffect } from 'react';
import { renderToString } from 'react-dom/server';
import PropTypes from 'prop-types';
import {
  LngLatLike,
  Map,
  Marker,
  Popup,
} from 'mapbox-gl';
import NotificationItem from 'components/notifications/NotificationItem';
import { INotificationItem } from 'interfaces';
import warningIcon from '../images/warning-icon.svg';

export interface NotificationsProps {
  map: Map;
  items: INotificationItem[];
}

const NotificationsOnMap = ({ map, items }: NotificationsProps) => {
  useEffect(() => {
    const markers: Marker[] = [];
    items.forEach((item: INotificationItem) => {
      const el = document.createElement('div');
      const pingElement = document.createElement('div');
      el.className = 'notification-marker w-7 h-7 cursor-pointer relative';
      pingElement.className = 'absolute w-5 h-5 bg-yellow-400 rounded-full animate-ping top-1 left-1 opacity-75';
      const markerImage = new Image();
      markerImage.src = warningIcon;
      markerImage.className = 'relative z-10';
      el.appendChild(pingElement);
      el.appendChild(markerImage);

      const popupContent = (
        <NotificationItem {...item} small />
      );
      const popup = new Popup({ closeButton: false })
        .setHTML(renderToString(popupContent))
        .setMaxWidth('500px')
        .setOffset(5);

      const marker = new Marker(el)
        .setLngLat(item.position as LngLatLike)
        .setPopup(popup)
        .addTo(map);

      markers.push(marker);
    });

    return () => markers.forEach((marker: Marker) => marker.remove());
  }, [map, items]);

  return null;
};

NotificationsOnMap.propTypes = {
  map: PropTypes.instanceOf(Map).isRequired,
  items: PropTypes.any,
};

export default NotificationsOnMap;
