export const Styles = {
  STREET: 'mapbox://styles/mapbox/streets-v11',
  SATELLITE: 'mapbox://styles/mapbox/satellite-v9',
};

export const DEFAULT_CENTER = [-117.06881059488416, 10.370702420301185];
export const DEFAULT_ZOOM = 10;

// Mapbox Layer styles
// https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/
export const SeaMountFill = {
  'fill-color': '#F6CF00',
  'fill-opacity': 0.6,
};

export const SeaMountLine = {
  'line-color': '#f3cc11',
  'line-width': 2,
};

export const BoxCoreCircle = {
  'circle-color': '#5B1FC0',
  'circle-stroke-color': '#29006C',
  'circle-radius': 6,
};

export const BoxCoreCircleShadow = {
  'circle-color': '#000000',
  'circle-blur': 0.8,
  'circle-opacity': 0.25,
  'circle-radius': 9,
  'circle-translate': [2, 2],
};

export const MinePathLine = {
  'line-color': '#802C8C',
  'line-width': 1,
  'line-opacity': 0.5,
};

export const MinedMinePathLine = {
  'line-color': '#AC1744',
  'line-width': 2,
  'line-opacity': 0.5,
};

export const MineBoxLine = {
  'line-color': '#E32F45',
  'line-width': 2,
};

export const CollectorCircle = {
  'circle-color': '#FFFFFF',
  'circle-stroke-color': '#B62C8C',
  'circle-stroke-width': 3,
  'circle-radius': 4,
};

export const CollectorAuraCircle = {
  'circle-color': '#FFFFFF',
  'circle-stroke-color': '#B62C8C',
  'circle-stroke-width': 1,
  'circle-radius': 10,
};
