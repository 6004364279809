import { useEffect, useState } from 'react';

import { IEcosystemServicesData } from 'interfaces';
import api from '../utils/Api';

const useIndicatorsData = (defaultData:any = null): IEcosystemServicesData => {
  const [indicators, setIndicators] = useState(defaultData);

  useEffect(() => {
    if (!indicators) {
      api.getIndicators().then((responseData) => setIndicators(responseData));
    }
  });

  return indicators;
};

export default useIndicatorsData;
