import axios, { AxiosRequestConfig } from 'axios';
import { API_SERVER } from '../config';

/**
 * Token Validator class
 */
export default class TokenValidator {
  /**
   * Validates token with the service
   * @param token
   */
  public static validate(token: string): Promise<boolean> {
    const data = {
      token,
    };
    const endpoint = `${API_SERVER}/authenticate`;
    const requestConfig:AxiosRequestConfig = {
      headers: {
        'Content-type': 'application/json',
      },
    };

    return axios.post(endpoint, JSON.stringify(data), requestConfig)
      .then((response) => response.status === 200)
      .catch(() => false);
  }
}
