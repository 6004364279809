import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { IPropsWithChildren, IPropsWithClassName } from 'interfaces';
import { ALIGNMENTS, CONTAINER_WIDTH } from 'enums';
import Button from 'components/button/button';
import Section, { BG_COLOR } from 'components/section/Section';

interface HeroPropCta {
  title: string;
  to: string;
  blank?: boolean;
}

export interface HeroProps extends IPropsWithClassName, IPropsWithChildren {
  lead: string;
  bgColor?: BG_COLOR;
  title?: string;
  subtitle?: string;
  alignment?: ALIGNMENTS;
  size?: CONTAINER_WIDTH;
  cta?: HeroPropCta;
}

const Hero = ({
  title,
  lead,
  cta,
  children,
  subtitle,
  size,
  className,
  alignment = ALIGNMENTS.CENTER,
  bgColor = BG_COLOR.GRAY,
}: HeroProps) => {
  const componentClasses = classNames(
    size,
    'font-sans',
    `text-${alignment}`,
  );

  const heroElement = (
    <section className={classNames(className)}>
      <div className="wrapper text-black">
        <div className={componentClasses}>
          {subtitle && <h2 className="mb-4 text-lg uppercase">{subtitle}</h2>}
          <h1 className="text-5xl font-medium mx-auto mb-6">
            {title}
          </h1>
          <p className="text-2xl font-normal leading-8 block">{lead}</p>
        </div>
      </div>
    </section>
  );

  return (
    <Section bgColor={bgColor} size={size}>
      {heroElement}
      <React.Fragment>
        {children
          && (
            <div className="mt-8 md:mt-16">
              { children }
            </div>
          )}
      </React.Fragment>
      <div className="mt-10 text-center">
        {cta && (
          <Button
            {...cta}
            type="link"
          />
        )}
      </div>
    </Section>
  );
};

Hero.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  lead: PropTypes.string.isRequired,
  cta: PropTypes.shape({
    title: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    blank: PropTypes.bool,
  }),
  size: PropTypes.string,
  className: PropTypes.string,
  alignment: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  bgColor: PropTypes.string,
};

Hero.defaultProps = {
  title: '',
  cta: null,
  className: null,
  subtitle: null,
  size: CONTAINER_WIDTH.XL7,
  alignment: ALIGNMENTS.CENTER,
  children: null,
  bgColor: BG_COLOR.GRAY,
};

export default Hero;
