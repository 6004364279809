import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import NavBar from 'components/navbar/navbar';
import Container from 'components/section/Container';
import { CONTAINER_WIDTH } from 'enums';
import { IPropsWithClassName } from 'interfaces';

import logoImg from 'images/logo-text.svg';

const timeElapsed = Date.now();
const today = new Date(timeElapsed);

export interface HeaderProps extends IPropsWithClassName {
  hideLinks: boolean;
}

const Header = ({ className, hideLinks = false }: HeaderProps) => {
  const componentClasses = classNames(
    'bg-warmGray-300',
    'pt-9',
    'pb-4',
    className,
  );
  return (
    <header className={componentClasses}>
      <Container size={CONTAINER_WIDTH.HEADER}>
        <div className="flex justify-between items-start md:justify-start md:space-x-10">
          <div className="flex justify-start">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link to="/home" className="inline-block" title="The Metals Company">
              <span className="sr-only">DeepGreen</span>
              <div className="w-auto dark:text-white">
                <img src={logoImg} alt="The Metals Company" height="106" />
              </div>
            </Link>
          </div>

          <div className={`hidden md:flex md:flex-col items-end md:flex-1 lg:w-0 ${hideLinks ? 'pb---16' : 'justify-end'}`}>
            <div className="ml-10 font-regular text-xs dark:text-white">
              {`Updated ${today.toDateString()}, ${today.toLocaleTimeString()}`}
            </div>
            {!hideLinks && <NavBar itemClassName="pt-6" />}
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Header;
