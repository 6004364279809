import { INotificationItem } from 'interfaces';
import { NotificationStatus } from 'enums';
import { stringToTimestamp } from 'utils/Date';

const firstEventMock: INotificationItem[] = [
  {
    status: NotificationStatus.EVENT_DETECTED,
    title: 'Oil leak detected',
    description: 'A minor oil leak was detected in the collector vehicle due to loss of control valve pressure.',
    date: 'September 25 at 1:00AM',
    position: {
      lat: 10.398056067398997,
      lng: -117.04649461588073,
    },
    notifications: [
      {
        author: 'Dominik J',
        novelty: 'confirmed and created an issue',
        date: 'September 25 at 1:00AM',
      },
      {
        author: 'Pressure Sensor',
        novelty: 'detected an oil leak',
        date: 'September 25 at 12:00AM',
      },
    ],
  },
  {
    status: NotificationStatus.IN_REVIEW,
    title: 'Oil leak detected',
    description: 'Proposal: Send ROV to monitor the area and take samples of water quality. Upon recovery, operator will repair the equipment.',
    date: 'September 25 at 12:14AM',
    position: {
      lat: 10.398056067398997,
      lng: -117.04649461588073,
    },
    notifications: [
      {
        author: 'AMS',
        novelty: 'proposed a plan',
        date: 'September 25 at 12:05AM',
      },
      {
        author: 'Dominik J',
        novelty: 'confirmed and created an issue',
        date: 'September 25 at 12:05AM',
      },
      {
        author: 'Pressure Sensor',
        novelty: 'detected an oil leak',
        description: 'A minor oil leak was detected in the collector vehicle due to loss of control valve pressure.',
        date: 'September 25 at 12:00AM',
      },
    ],
  },
  {
    status: NotificationStatus.IN_PROGRESS,
    title: 'Oil leak detected',
    description: 'Action: ROV stationed in leak area to continue monitoring and gathering water quality data. Collector vehicle recovered, repaired, and redeployed.',
    date: 'September 25 at 12:28AM',
    position: {
      lat: 10.398056067398997,
      lng: -117.04649461588073,
    },
    notifications: [
      {
        author: 'Dominik J',
        novelty: 'implemented a plan',
        date: 'September 25 at 12:28AM',
      },
      {
        author: 'AMS',
        novelty: 'proposed a plan',
        description: 'Send ROV to monitor the area and take samples of water quality. Upon recovery, operator will repair the equipment.',
        date: 'September 25 at 12:14AM',
      },
      {
        author: 'Dominik J',
        novelty: 'confirmed and created an issue',
        date: 'September 25 at 12:05AM',
      },
      {
        author: 'Pressure Sensor',
        novelty: 'detected an oil leak',
        description: 'A minor oil leak was detected in the collector vehicle due to loss of control valve pressure.',
        date: 'September 25 at 12:00AM',
      },
    ],
  },
];

const secondEventMock: INotificationItem[] = [
  {
    status: NotificationStatus.EVENT_DETECTED,
    title: 'Tracked hurricane moving towards mining path',
    description: 'The crew were tracking a nearby hurricane during operations when new NOAA data was released forecasting the hurricane to move over the collection operations.',
    date: 'August 2 at 2:45PM',
    position: {
      lat: 10.50677150258845,
      lng: -117.04100145181823,
    },
    notifications: [
      {
        author: 'Dominik J',
        novelty: 'confirmed and created an issue',
        date: 'August 2 at 2:45PM',
      },
      {
        author: 'NOAA data',
        novelty: 'detected a weather pattern',
        date: 'August 2 at 2:30PM',
      },
    ],
  },
  {
    status: NotificationStatus.IN_REVIEW,
    title: 'Tracked hurricane moving towards mining path',
    description: 'Proposal: The riser to be recovered to deck and the ship instructed to shelter in place. The collector vehicle to be detached and put into hibernation on the seafloor.',
    date: 'August 2 at 7:18PM',
    position: {
      lat: 10.50677150258845,
      lng: -117.04100145181823,
    },
    notifications: [
      {
        author: 'AMS',
        novelty: 'proposed a plan',
        date: 'August 2 at 7:18PM',
      },
      {
        author: 'Dominik J',
        novelty: 'confirmed and created an issue',
        date: 'August 2 at 2:45PM',
      },
      {
        author: 'NOAA data',
        novelty: 'detected a weather pattern',
        description: 'The crew were tracking a nearby hurricane during operations when new NOAA data was released forecasting the hurricane to move over the collection operations.',
        date: 'August 2 at 2:30PM',
      },
    ],
  },
  {
    status: NotificationStatus.IN_PROGRESS,
    title: 'Tracked hurricane moving towards mining path',
    description: 'Action: Hurricane passed and riser redeployed. Collector vehicle reconnected and operations have resumed.',
    date: 'September 25 at 12:30AM',
    position: {
      lat: 10.50677150258845,
      lng: -117.04100145181823,
    },
    notifications: [
      {
        author: 'Dominik J',
        novelty: 'implemented a plan',
        date: 'August 2 at 8:00PM',
      },
      {
        author: 'AMS',
        novelty: 'proposed a plan',
        description: 'The riser to be recovered to deck and the ship instructed to shelter in place. The collector vehicle to be detached and put into hibernation on the seafloor.',
        date: 'August 2 at 7:18PM',
      },
      {
        author: 'Dominik J',
        novelty: 'confirmed and created an issue',
        date: 'August 2 at 2:45PM',
      },
      {
        author: 'NOAA data',
        novelty: 'detected a weather pattern',
        description: 'The crew were tracking a nearby hurricane during operations when new NOAA data was released forecasting the hurricane to move over the collection operations.',
        date: 'August 2 at 2:30PM',
      },
    ],
  },
];

export const makeTimestamps = (
  items: INotificationItem[],
): INotificationItem[] => items.map((item: INotificationItem) => {
  const timestamp = stringToTimestamp(item.date);

  return {
    ...item,
    timestamp,
  };
});

const eventsMock = [
  makeTimestamps(firstEventMock),
  makeTimestamps(secondEventMock),
];

export default eventsMock;
