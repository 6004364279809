import React, { useEffect } from 'react';

import PageTitleContext from 'context/PageTitleContext';
import { CONTAINER_WIDTH } from 'enums';
import Section, { BG_COLOR } from 'components/section/Section';

const Maintenance = () => {
  const { setPageTitle } = React.useContext(PageTitleContext);

  useEffect(() => {
    setPageTitle('Maintenance');
  }, [setPageTitle]);

  return (
    <Section size={CONTAINER_WIDTH.XL4} className="text-center" bgColor={BG_COLOR.GRAY}>
      <h1 className="text-5xl font-medium mx-auto mb-6">
        We are down for maintenance
      </h1>
      <p>We are performing scheduled maintenance. We’ll be back online shortly.</p>
    </Section>
  );
};

export default Maintenance;
