/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext } from 'react';

const MapOperationsContext = createContext({
  showBoxCores: true,
  showSeaMounts: true,
  showMineBox: true,
  showMinePath: true,
  showMinedMinePath: true,
  showCollector: true,
  simulatedTime: '',
  setShowBoxCores: (value: boolean) => {},
  setShowSeaMounts: (value: boolean) => {},
  setShowMineBox: (value: boolean) => {},
  setShowMinePath: (value: boolean) => {},
  setShowMinedMinePath: (value: boolean) => {},
  setShowCollector: (value: boolean) => {},
  setSimulatedTime: (value: string) => {},
});

export default MapOperationsContext;
