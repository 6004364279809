import { INotificationItem } from 'interfaces';
import { NotificationStatus } from 'enums';
import { makeTimestamps } from './notifications.mock';

const firstEventMock: INotificationItem[] = [
  {
    status: NotificationStatus.EVENT_DETECTED,
    predicted: true,
    title: 'Benthic plume TSS at maximum exceedance levels',
    description: 'The AMS has predicted that if operations continue with current targets, the benthic plume TSS (total suspended sediment) will exceed the maximum allowed levels',
    date: 'May 12 at 1:00AM',
    position: {
      lat: 10.367662978534682,
      lng: -116.90435899576367,
    },
    notifications: [
      {
        author: 'Dominik J',
        novelty: 'confirmed and created an issue',
        date: 'May 12 at 1:20AM',
      },
      {
        author: 'AMS',
        novelty: 'detected predicted plume exceedence levels',
        date: 'May 12 at 1:00AM',
      },
    ],
  },
  {
    status: NotificationStatus.IN_REVIEW,
    predicted: true,
    title: 'Benthic plume TSS at maximum exceedance levels',
    description: 'Proposal: Early warning alert to C&C operators that an incident has been inferred. Use the AMS to model the impact and output a new scenario. Either update the collection plan parameters or change location.',
    date: 'May 18 at 4:48AM',
    position: {
      lat: 10.367662978534682,
      lng: -116.90435899576367,
    },
    notifications: [
      {
        author: 'AMS',
        novelty: 'proposed a plan',
        date: 'May 18 at 4:48AM',
      },
      {
        author: 'Dominik J',
        novelty: 'confirmed and created an issue',
        date: 'May 12 at 1:20AM',
      },
      {
        author: 'AMS',
        novelty: 'detected predicted plume exceedence levels',
        description: 'The AMS has predicted that if operations continue with current targets, the benthic plume TSS (total suspended sediment) will exceed the maximum allowed levels',
        date: 'May 12 at 1:00AM',
      },
    ],
  },
  {
    status: NotificationStatus.IN_PROGRESS,
    predicted: true,
    title: 'Benthic plume TSS at maximum exceedance levels',
    description: 'Action: Nodule abundance being monitored for changes while continuing collection.',
    date: 'May 26 at 5:20AM',
    position: {
      lat: 10.367662978534682,
      lng: -116.90435899576367,
    },
    notifications: [
      {
        author: 'Dominik J',
        novelty: 'implemented a plan',
        date: 'May 26 at 5:20AM',
      },
      {
        author: 'AMS',
        novelty: 'proposed a plan',
        description: 'Early warning alert to C&C operators that an incident has been inferred. Use the AMS to model the impact and output a new scenario. Either update the colletion plan parameters or change location.',
        date: 'May 18 at 4:48AM',
      },
      {
        author: 'Dominik J',
        novelty: 'confirmed and created an issue',
        date: 'May 12 at 1:20AM',
      },
      {
        author: 'AMS',
        novelty: 'detected predicted plume exceedence levels',
        description: 'The AMS has predicted that if operations continue with current targets, the benthic plume TSS (total suspended sediment) will exceed the maximum allowed levels.',
        date: 'May 12 at 1:00AM',
      },
    ],
  },
];

const secondEventMock: INotificationItem[] = [
  {
    status: NotificationStatus.EVENT_DETECTED,
    predicted: true,
    title: 'Midwater TSS discharge at maximum exceedance levels',
    description: 'A minor oil leak was detected in the collector vehicle due to loss of control valve pressure.',
    date: 'June 07 at 12:00AM',
    position: {
      lat: 10.493268552190358,
      lng: -117.20236314615403,
    },
    notifications: [
      {
        author: 'Dominik J',
        novelty: 'confirmed and created an issue',
        date: 'June 07 at 12:00AM',
      },
      {
        author: 'AMS',
        novelty: 'detected predicted exceeded levels',
        date: 'June 07 at 12:00AM',
      },
    ],
  },
  {
    status: NotificationStatus.IN_REVIEW,
    predicted: true,
    title: 'Midwater TSS discharge at maximum exceedance levels',
    description: 'Proposal: Increase monitoring and use the AMS to recompute the production block parameters.',
    date: 'June 11 at 12:00AM',
    position: {
      lat: 10.493268552190358,
      lng: -117.20236314615403,
    },
    notifications: [
      {
        author: 'AMS',
        novelty: 'proposed a plan',
        date: 'June 07 at 12:00A',
      },
      {
        author: 'Dominik J',
        novelty: 'confirmed and created an issue',
        date: 'June 07 at 12:00AM',
      },
      {
        author: 'AMS',
        novelty: 'detected predicted exceeded levels',
        description: 'The AMS has predicted that if operations continue with current targets, the benthic plume TSS (total suspended sediment) will exceed the maximum allowed levels',
        date: 'June 07 at 12:00AM',
      },
    ],
  },
  {
    status: NotificationStatus.IN_PROGRESS,
    predicted: true,
    title: 'Midwater TSS discharge at maximum exceedance levels',
    description: 'Action: Increased monitoring of midwater discharge and the production block parameters have been recomputed..',
    date: 'June 17 at 12:00AM',
    position: {
      lat: 10.493268552190358,
      lng: -117.20236314615403,
    },
    notifications: [
      {
        author: 'Dominik J',
        novelty: 'implemented a plan',
        date: 'June 17 at 12:00AM',
      },
      {
        author: 'AMS',
        novelty: 'proposed a plan',
        description: 'Increase monitoring and use the AMS to recompute the production block parameters.',
        date: 'June 07 at 12:00A',
      },
      {
        author: 'Dominik J',
        novelty: 'confirmed and created an issue',
        date: 'June 07 at 12:00AM',
      },
      {
        author: 'AMS',
        novelty: 'detected predicted exceeded levels',
        description: 'The AMS has predicted that if operations continue with current targets, the benthic plume TSS (total suspended sediment) will exceed the maximum allowed levels',
        date: 'June 07 at 12:00AM',
      },
    ],
  },
];

const thirdEventMock: INotificationItem[] = [
  {
    status: NotificationStatus.EVENT_DETECTED,
    predicted: true,
    title: 'Collector noise levels exceeding predicted parameters',
    description: 'The nodule pickup and transportation process through the collector vehicle and jumper hose generate noise which carries through the environment and may disrupt organisms behaviors. The current production rate is generating an excess of noise which may detrimentally impact the nearby ecosystem.',
    date: 'June 12 at 12:00AM',
    position: {
      lat: 10.225789693573859,
      lng: -117.08357347330256,
    },
    notifications: [
      {
        author: 'Dominik J',
        novelty: 'confirmed and created an issue',
        date: 'June 12 at 12:00AM',
      },
      {
        author: 'AMS',
        novelty: 'detected predicted exceeded levels',
        date: 'June 12 at 12:00AM',
      },
    ],
  },
  {
    status: NotificationStatus.IN_REVIEW,
    predicted: true,
    title: 'Collector noise levels exceeding predicted parameters',
    description: 'Proposal: Increase monitoring and reduce the production target for a time period. Schedule inspection to check for faulty equipment which may be causing increased noise levels.',
    date: 'June 12 at 1:20AM',
    position: {
      lat: 10.225789693573859,
      lng: -117.08357347330256,
    },
    notifications: [
      {
        author: 'AMS',
        novelty: 'proposed a plan',
        date: 'June 12 at 1:20AM',
      },
      {
        author: 'Dominik J',
        novelty: 'confirmed and created an issue',
        date: 'June 12 at 12:00AM',
      },
      {
        author: 'AMS',
        novelty: 'detected predicted exceeded levels',
        description: 'The nodule pickup and transportation process through the collector vehicle and jumper hose generate noise which carries through the environment and may disrupt organisms behaviors. The current production rate is generating an excess of noise which may detrimentally impact the nearby ecosystem.',
        date: 'June 12 at 12:00AM',
      },
    ],
  },
  {
    status: NotificationStatus.IN_PROGRESS,
    predicted: true,
    title: 'Collector noise levels exceeding predicted parameters',
    description: 'Action: After inspection, deteriorated equipment was replaced and the system redeployed.',
    date: 'July 02 at 6:00PM',
    position: {
      lat: 10.225789693573859,
      lng: -117.08357347330256,
    },
    notifications: [
      {
        author: 'Dominik J',
        novelty: 'implemented a plan',
        date: 'July 02 at 6:00PM',
      },
      {
        author: 'AMS',
        novelty: 'proposed a plan',
        description: 'Increase monitoring and reduce the production target for a time period. Schedule inspection to check for faulty equipment which may be causing increased noise levels.',
        date: 'June 12 at 1:20AM',
      },
      {
        author: 'Dominik J',
        novelty: 'confirmed and created an issue',
        date: 'June 12 at 12:00AM',
      },
      {
        author: 'AMS',
        novelty: 'detected predicted exceeded levels',
        description: 'The nodule pickup and transportation process through the collector vehicle and jumper hose generate noise which carries through the environment and may disrupt organisms behaviors. The current production rate is generating an excess of noise which may detrimentally impact the nearby ecosystem.',
        date: 'June 12 at 12:00AM',
      },
    ],
  },
];

const fourthEventMock: INotificationItem[] = [
  {
    status: NotificationStatus.EVENT_DETECTED,
    predicted: true,
    title: 'Forecasted Change in Ocean Currents',
    description: 'Updated ocean currents and climate has been calculated for the next season. Some collection plan\'s benthic plume are predicted to expand over a protected zone. As a result the necessary collection plans are to be recomputed with adjusted production targets.',
    date: 'July 22 at 12:00AM',
    position: {
      lat: 10.319027904045498,
      lng: -116.82333482584136,
    },
    notifications: [
      {
        author: 'Dominik J',
        novelty: 'confirmed and created an issue',
        date: 'July 22 at 12:00AM',
      },
      {
        author: 'AMS',
        novelty: 'detected predicted change in ocean currents',
        date: 'July 22 at 12:00AM',
      },
    ],
  },
  {
    status: NotificationStatus.IN_REVIEW,
    predicted: true,
    title: 'Forecasted Change in Ocean Currents',
    description: 'Proposal: Update model parameters and recompute collection plan',
    date: 'July 22 at 2:24AM',
    position: {
      lat: 10.319027904045498,
      lng: -116.82333482584136,
    },
    notifications: [
      {
        author: 'AMS',
        novelty: 'proposed a plan',
        date: 'July 22 at 2:24AM',
      },
      {
        author: 'Dominik J',
        novelty: 'confirmed and created an issue',
        date: 'July 22 at 12:00AM',
      },
      {
        author: 'AMS',
        novelty: 'detected predicted change in ocean currents',
        description: 'Updated ocean currents and climate has been calculated for the next season. Some collection plan\'s benthic plume are predicted to expand over a protected zone. As a result the necessary collection plans are to be recomputed with adjusted production targets.',
        date: 'July 22 at 12:00AM',
      },
    ],
  },
  {
    status: NotificationStatus.IN_PROGRESS,
    predicted: true,
    title: 'Forecasted Change in Ocean Currents',
    description: 'Action: Collection plan recomputed and collector is now executing new collection plan',
    date: 'July 22 at 9:36AM',
    position: {
      lat: 10.319027904045498,
      lng: -116.82333482584136,
    },
    notifications: [
      {
        author: 'Dominik J',
        novelty: 'implemented a plan',
        date: 'July 22 at 9:36AM',
      },
      {
        author: 'AMS',
        description: 'Update model parameters and recompute collection plan',
        novelty: 'proposed a plan',
        date: 'July 22 at 2:24AM',
      },
      {
        author: 'Dominik J',
        novelty: 'confirmed and created an issue',
        date: 'July 22 at 12:00AM',
      },
      {
        author: 'AMS',
        novelty: 'detected predicted change in ocean currents',
        description: 'Updated ocean currents and climate has been calculated for the next season. Some collection plan\'s benthic plume are predicted to expand over a protected zone. As a result the necessary collection plans are to be recomputed with adjusted production targets.',
        date: 'July 22 at 12:00AM',
      },
    ],
  },
];

const fifthEventMock: INotificationItem[] = [
  {
    status: NotificationStatus.EVENT_DETECTED,
    predicted: true,
    title: 'Cumulative Impacts',
    description: 'Contractors operating in adjacent lease areas are conducting collection at tangent collection paths. The combined impacts may exceed limits.',
    date: 'July 28 at 12:00AM',
    position: {
      lat: 10.408861786547249,
      lng: -117.11103929361505,
    },
    notifications: [
      {
        author: 'Dominik J',
        novelty: 'confirmed and created an issue',
        date: 'July 28 at 12:00AM',
      },
      {
        author: 'AMS',
        novelty: 'detected predicted exceeded levels',
        date: 'July 28 at 12:00AM',
      },
    ],
  },
  {
    status: NotificationStatus.IN_REVIEW,
    predicted: true,
    title: 'Cumulative Impacts',
    description: 'Proposal: Coordination and sharing of information was done with the adjacent contractor. New data to be ingested by the AMS and an alternative scenario to be executed.',
    date: 'August 4 at 7:12AM',
    position: {
      lat: 10.408861786547249,
      lng: -117.11103929361505,
    },
    notifications: [
      {
        author: 'AMS',
        novelty: 'proposed a plan',
        date: 'August 4 at 7:12AM',
      },
      {
        author: 'Dominik J',
        novelty: 'confirmed and created an issue',
        date: 'July 28 at 12:00AM',
      },
      {
        author: 'AMS',
        novelty: 'detected predicted exceeded levels',
        description: 'Contractors operating in adjacent lease areas are conducting collection at tangent collection paths. The combined impacts may exceed limits.',
        date: 'July 28 at 12:00AM',
      },
    ],
  },
  {
    status: NotificationStatus.IN_PROGRESS,
    predicted: true,
    title: 'Cumulative Impacts',
    description: 'Action: AMS recalculated the production target while passing adjacent contractor lease area to reduce total impact between operators.',
    date: 'August 13 at 9:36AM',
    position: {
      lat: 10.408861786547249,
      lng: -117.11103929361505,
    },
    notifications: [
      {
        author: 'Dominik J',
        novelty: 'implemented a plan',
        date: 'August 13 at 9:36AM',
      },
      {
        author: 'AMS',
        novelty: 'proposed a plan',
        description: 'Coordination and sharing of information was done with the adjacent contractor. New data to be ingested by the AMS and an alternative scenario to be executed.',
        date: 'August 4 at 7:12AM',
      },
      {
        author: 'Dominik J',
        novelty: 'confirmed and created an issue',
        date: 'July 28 at 12:00AM',
      },
      {
        author: 'AMS',
        novelty: 'detected predicted exceeded levels',
        description: 'Contractors operating in adjacent lease areas are conducting collection at tangent collection paths. The combined impacts may exceed limits.',
        date: 'July 28 at 12:00AM',
      },
    ],
  },
];

const eventsPredictedMock = [
  makeTimestamps(firstEventMock),
  makeTimestamps(secondEventMock),
  makeTimestamps(thirdEventMock),
  makeTimestamps(fourthEventMock),
  makeTimestamps(fifthEventMock),
];

export default eventsPredictedMock;
