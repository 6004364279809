import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import classNames from 'classnames';

import { INotificationItem, IPropsWithClassName } from 'interfaces';
import sanitizeUrl from 'utils/sanitizeUrl';
import Badge, { BadgeType } from '../badge/badge';
import NotificationItemBar from './NotificationItemBar';

import './NotificationItem.css';

export interface NotificationItemProps extends IPropsWithClassName, INotificationItem {
  small?: boolean;
  collapsed?: boolean;
}

const NotificationItem = ({
  title,
  status,
  description,
  date,
  predicted,
  small = false,
  notifications,
  className,
  collapsed = true,
}: NotificationItemProps) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);
  const componentClasses = classNames(
    'mb-6',
    'border-gray-300',
    'bg-white',
    className,
    {
      border: !small,
      'border-solid': !small,
      rounded: !small,
    },
  );

  const buttonClasses = classNames(
    'plus-button',
    'relative',
    'rounded-full',
    'bg-gray-200',
    'w-12',
    'h-12',
    'transition-colors',
    'hover:bg-gray-300',
    {
      open: !isCollapsed,
    },
  );

  const toggleNotifications = () => {
    setIsCollapsed(!isCollapsed);
  };
  const notificationId = sanitizeUrl(title);
  const dateWidth = notifications?.length ? 'w-48' : 'w-72';
  const descriptionClasses = classNames(
    {
      'text mt-1 text-sm text-blueGray-900': !small,
      'text mt-1 text-sm text-amber-800 bg-amber-50 px-3 py-3': small,
    },
  );
  const containerClasses = classNames(
    'flex',
    'w-full',
    'justify-between',
    {
      'p-2 sm:p-4': !small,
    },
  );

  useEffect(() => {
    if (collapsed !== isCollapsed) {
      setIsCollapsed(collapsed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapsed, setIsCollapsed]);

  return (
    <article className={componentClasses}>
      {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */}
      { !small && <a id={notificationId} />}
      <div className={containerClasses}>
        <div className="flex-auto overflow-hidden">
          <Badge type={status} text={status} />
          { predicted && <Badge type={BadgeType.YELLOW} text="Predicted" className="ml-2" />}
          <h3 className="mt-4 text-blue-700">{title}</h3>
          { small && <div className="my-3 pt-2 border-t border-gray-300" />}
          {
            description && (
              <div className={descriptionClasses}>{ description }</div>
            )
          }
          { !small && <NotificationItemBar status={status} className="mt-6" /> }
        </div>
        { !small && <div className={`text-xs flex-shrink-0 ${dateWidth} px-4 pt-2 text-right text-blueGray-900`}>{date}</div> }
        {
          !small && notifications?.length
            ? (
              <div className="px-4 flex items-center justify-between">
                <button
                  className={buttonClasses}
                  onClick={toggleNotifications}
                  type="button"
                >
                  {isCollapsed && <span className="sr-only">Show notifications</span>}
                  {!isCollapsed && <span className="sr-only">Hide notifications</span>}
                </button>
              </div>
            )
            : null
        }
      </div>
      { !small && !isCollapsed && (
        <div className="border-t border-solid-t border-gray-300">
          <div className="p-2 sm:p-4">
            {
              _map(notifications, ({
                author, novelty, date: notificationDate, description: notificationDescription,
              }, index) => {
                const marginTop = index > 0 ? 'mt-4' : '';
                return (
                  <div key={author + notificationDate} className={`${marginTop} p-2 sm:p-4 border border-solid rounded border-gray-300 bg-white relative text-blueGray-900`}>
                    <div className="line-separator border-l border-gray-300 h-4 w-1 absolute" />
                    <div className="flex text-xs">
                      { author && <div className="mr-2">{ author }</div> }
                      { novelty && <div className="mr-6">{ novelty }</div> }
                      { notificationDate && <div>{ notificationDate }</div> }
                    </div>
                    { notificationDescription && <div className="mt-4 text-sm text-blueGray-700">{ notificationDescription }</div> }

                  </div>
                );
              })
            }
          </div>
        </div>
      ) }
    </article>
  );
};

NotificationItem.propTypes = {
  status: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  date: PropTypes.string.isRequired,
  predicted: PropTypes.bool,
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.string.isRequired,
      novelty: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      description: PropTypes.string,
    }),
  ),
  className: PropTypes.string,
  small: PropTypes.bool,
  collapsed: PropTypes.bool,
};

NotificationItem.defaultProps = {
  className: null,
  description: null,
  predicted: null,
  notifications: [],
  small: false,
  collapsed: true,
};

export default NotificationItem;
