import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _forEach from 'lodash/forEach';
import _isNil from 'lodash/isNil';

import ChartSankey from 'components/chart_sankey/ChartSankey';
import {
  IChartSankeyDataIndicators,
  IChartSankeyLabels,
  IEcosystemServicesProps,
  ISankeyClickEvent,
} from 'interfaces';

import { SANKEY_CLICK_TYPE } from 'enums';
import ChartLevels from 'components/chart_levels/ChartLevels';
import { activeIndicators, mergeIndicators } from 'utils/EcosytemService';
import useIndicatorsData from 'hooks/useIndicatorsData';
import Info from './Info';

const COMPONENT_CLASS = 'ecosystem-services';
const SUBTITLE_SEPARATOR = '/';

interface ISelectedItem {
  indicators: IChartSankeyDataIndicators[] | null;
  type?: SANKEY_CLICK_TYPE | null,
  title: string;
  subtitle: string;
  description: string;
  level?: number;
  className?: string;
}

export const separatorsLabels: IChartSankeyLabels[] = [
  { title: 'SERVICES' },
  { title: 'FUNCTIONS' },
  { title: 'INDICATORS' },
];

const EcosystemServices = ({ className, disabledClick = false }: IEcosystemServicesProps) => {
  const defaultSelectedItem: ISelectedItem = {
    indicators: null,
    type: null,
    title: '',
    subtitle: '',
    description: '',
  };
  const [selectedItem, setSelectedItem] = useState(defaultSelectedItem);
  const indicatorsData = useIndicatorsData(null);
  const componentClasses = classNames(
    COMPONENT_CLASS,
    'max-w-7xl',
    'mx-auto',
    'px-4',
    className,
  );

  const onSankeyChartClick = (params: ISankeyClickEvent) => {
    if (disabledClick) {
      return;
    }
    const {
      type,
      data,
      serviceIndex,
      functionIndex,
    } = params;
    const { name: title, description = '' } = data;
    let subtitle = 'Service';
    let level = 0;
    let currentService;
    const serviceData = type === SANKEY_CLICK_TYPE.SERVICE
      ? data
      : indicatorsData.data[serviceIndex];
    let indicators = mergeIndicators(serviceData);
    if (selectedItem.title === title) {
      return;
    }
    const newSelectedItem: ISelectedItem = {
      type,
      indicators,
      title,
      subtitle,
      level,
      description,
      className: type,
    };

    const getMaxLevel = (levelsData: any[]): number => {
      let maxLevel = 0;
      _forEach(levelsData, ({ levelItem = 0 }) => {
        maxLevel = (levelItem && levelItem > maxLevel) ? levelItem : maxLevel;
      });
      return maxLevel;
    };

    if (type === SANKEY_CLICK_TYPE.FUNCTION || type === SANKEY_CLICK_TYPE.INDICATOR) {
      currentService = indicatorsData.data[serviceIndex] || {};
      const { name: serviceName = '' } = currentService;
      subtitle = serviceName;
      indicators = activeIndicators(indicators, title, type === SANKEY_CLICK_TYPE.FUNCTION);

      if (type === SANKEY_CLICK_TYPE.FUNCTION) {
        level = getMaxLevel(data.indicators);
      }
    }

    if (type === SANKEY_CLICK_TYPE.INDICATOR) {
      const { functions = [] } = currentService || {};
      const currentFunction = !_isNil(functionIndex) ? functions[functionIndex] : { name: '' };
      const { name: functionName = '' } = currentFunction;
      subtitle += ` ${SUBTITLE_SEPARATOR} ${functionName}`;
      level = data.level || 0;
    }
    newSelectedItem.subtitle = subtitle;
    newSelectedItem.level = level;
    newSelectedItem.indicators = indicators;
    setSelectedItem(newSelectedItem);
  };

  const otherProps = {} as any;

  if (!disabledClick) {
    otherProps.onClick = onSankeyChartClick;
  }

  if (!indicatorsData) {
    return <div>Loading data...</div>;
  }

  return (
    <section className={componentClasses}>
      <article className="sankey-container overflow-x-auto xl:overflow-hidden">
        <ChartSankey
          data={indicatorsData.data}
          {...otherProps}
          separatorsLabels={separatorsLabels}
          selectedItemIndex={disabledClick ? null : 0}
        />
      </article>
      {
        selectedItem && selectedItem.indicators && (
          <article
            className="info grid grid-cols-1 lg:grid-cols-2 gap-6 p-6 rounded-md border border-solid border-gray-300 mt-6"
          >
            <Info
              description={selectedItem.description}
              title={selectedItem.title}
              subtitle={selectedItem.subtitle}
              className={selectedItem.className}
              level={selectedItem.level}
            />
            <ChartLevels
              periods={indicatorsData.periods}
              indicators={selectedItem.indicators}
              beforeActive={selectedItem.type === SANKEY_CLICK_TYPE.SERVICE}
            />
          </article>
        )
      }

    </section>
  );
};

EcosystemServices.propTypes = {
  className: PropTypes.string,
};

EcosystemServices.defaultProps = {
  className: '',
};

export default EcosystemServices;
