import React from 'react';
import { Popover } from '@headlessui/react';

import infoBC from './images/info-box-cores.svg';
import infoMB from './images/info-mine-block.svg';
import infoMP from './images/info-mine-path.svg';
import infoPZ from './images/info-preservation-zones.svg';

interface LegendLayerProps {
  icon: any,
  label: string,
  tooltip: string,
}
const LegendLayer = ({ icon, label, tooltip }: LegendLayerProps) => (
  <Popover className="bg-gray-200 relative px-5 py-2 rounded-full">
    <Popover.Button className="flex gap-3 focus:outline-none items-center">
      {icon && <img src={icon} alt="" />}
      <span>{label}</span>
    </Popover.Button>

    <Popover.Panel className="absolute z-10 bottom-12">
      <div className="bg-white p-3 rounded-md shadow-md text-sm max-w-lg transform -translate-x-1/2 w-80">
        {tooltip}
      </div>
    </Popover.Panel>
  </Popover>
);

const LegendLayers = () => (
  <div className="flex text-blueGray-700 items-center gap-4">
    <LegendLayer icon={infoMB} label="Collection block" tooltip="A section of the resource suitable for nodule collection" />
    <LegendLayer icon={infoMP} label="Collection path" tooltip="The exact path the collector vehicle will traverse" />
    <LegendLayer icon={infoBC} label="Box cores" tooltip="A sample of seabed extracted to faciliate biological and geological data collection." />
    <LegendLayer icon={infoPZ} label="Seamounts" tooltip="Areas of the seabed set aside because they are not accessible to the collector due to elevation change" />
  </div>
);

export default LegendLayers;
