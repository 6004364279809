import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Selection } from 'd3';
import _isNil from 'lodash/isNil';

import { IChartSankeyProps, ISankeyClickEvent } from 'interfaces';
import { SANKEY_CLICK_TYPE } from 'enums';
import {
  COLORS,
  MARGINS,
  NODES_CONFIG,
  WIDTH,
} from './config';
import {
  getLinkWidth,
  makeLink,
  makeSvg,
  selectElement,
} from './svg-utils';

import './ChartSankey.css';

interface IClickExtraData {
  data: any;
  d3Element?: any;
  serviceIndex?: number;
  functionIndex?: number;
}

const ChartSankey = ({
  data,
  separatorsLabels,
  selectedItemIndex,
  onClick,
}: IChartSankeyProps) => {
  const [chartData, setChartData] = useState(data);
  let svg: Selection<SVGGElement, unknown, null, undefined>;
  const chartRef = React.createRef<HTMLDivElement>();

  const onElementClick = (elementType: SANKEY_CLICK_TYPE, extraData?: IClickExtraData) => {
    const { data: elementData, serviceIndex } = extraData || {};
    if (onClick && typeof onClick === 'function') {
      onClick({ type: elementType, ...extraData } as ISankeyClickEvent);

      const newData = chartData.map((item, index) => {
        item.active = item.name === elementData.name;

        item.functions = item?.functions?.map((functionItem) => {
          functionItem.active = functionItem.name === elementData.name && index === serviceIndex;

          functionItem.indicators = functionItem?.indicators?.map((indicator) => {
            indicator.active = indicator.name === elementData.name;

            return indicator;
          });

          return functionItem;
        });

        return item;
      });
      setChartData(newData);
    }
  };

  const drawChart = (element: HTMLElement) => {
    svg = makeSvg(element);
    const links: any[] = [];
    const functionsQuantity = data.reduce((count, current) => {
      const { functions = [] } = current;
      return count + functions.length;
    }, 0);

    const indicatorsQuantity = data.reduce((count, service) => {
      const { functions = [] } = service;
      const indicatorsCount = functions.reduce((counter, functionItem) => {
        const { indicators = [] } = functionItem;
        return counter + indicators.length;
      }, 0);

      return count + indicatorsCount;
    }, 0);
    const functionRadius = NODES_CONFIG.FUNCTION?.radius || 0;
    const indicatorRadius = NODES_CONFIG.INDICATOR?.radius || 0;

    const functionMargin = Math.floor((WIDTH + functionRadius / 2) / functionsQuantity);
    const indicatorsMargin = Math.floor((WIDTH + indicatorRadius / 2) / indicatorsQuantity);
    let functionsCounter = 0;
    let indicatorsCounter = 0;

    const tooltip = selectElement('.chart-tooltip');
    const showTooltip = (currentTarget: HTMLElement | SVGElement, title: string) => {
      const bounds = currentTarget.getBoundingClientRect();
      const MISMATCH = 10;
      const top = bounds.y + bounds.height + window.scrollY + MISMATCH;
      tooltip.html(title)
        .classed('opacity-0', false)
        .classed('left', false)
        .classed('right', false)
        .style('top', `${top}px`);

      const tooltipNode = tooltip.node() as HTMLElement || {};
      const {
        width: nodeWidth,
      } = tooltipNode?.getBoundingClientRect() || {};
      const centerLeft = (bounds.x + (bounds.width / 2)) - (nodeWidth / 2);
      let left = centerLeft;
      if (centerLeft < 0) {
        left = bounds.x;
        tooltip.classed('left', true);
      } else if (centerLeft + nodeWidth / 2 > WIDTH) {
        left = (bounds.x + bounds.width) - nodeWidth;
        tooltip.classed('right', true);
      }
      tooltip.style('left', `${left}px`);
    };

    const hideTooltip = () => {
      tooltip.classed('opacity-0', true);
    };

    chartData.forEach((service, serviceIndex) => {
      const { name: serviceName, functions = [], active = false } = service;
      const nodeXPosition = (serviceIndex * NODES_CONFIG.SERVICE.width)
        + (serviceIndex * NODES_CONFIG.SERVICE.margin);
      const node = svg.append('g')
        .attr('class', 'node-service cursor-pointer')
        .classed('active', active)
        .attr('transform', `translate(${nodeXPosition}, ${NODES_CONFIG.SERVICE.yInitial})`)
        .on('click', () => {
          onElementClick(SANKEY_CLICK_TYPE.SERVICE, { data: service });
        });
      node.append('rect')
        .attr('width', NODES_CONFIG.SERVICE.width)
        .attr('height', NODES_CONFIG.SERVICE.height)
        .attr('rx', 6)
        .attr('ry', 6)
        .append('title')
        .text(serviceName);

      const words = serviceName.split(' ');
      const manyWords = words.length > 1;
      const twoLines = serviceName.length > 11; /* 11 letters in order to make two lines */
      let textY = NODES_CONFIG.SERVICE.height / 2;
      const makeText = (text: string, y: number) => {
        node.append('text')
          .text(text)
          .attr('class', 'service-text-node pointer-events-none select-none')
          .attr('x', NODES_CONFIG.SERVICE.width / 2)
          .attr('y', y)
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .attr('dominant-baseline', 'middle');
      };

      if (manyWords && twoLines) {
        textY -= (words.length - 1) * 10; /* Control the top position if we have many words */
        words.forEach((word, wordIndex) => {
          makeText(word, textY + (wordIndex * 20));
        });
      } else {
        makeText(serviceName, textY);
      }

      service.source = [
        nodeXPosition + NODES_CONFIG.SERVICE.width / 2,
        NODES_CONFIG.LINK.gap + NODES_CONFIG.SERVICE.height,
      ];

      const howManyFunctions = functions.length;
      const halfFunctions = howManyFunctions / 2;
      const linkWidth = getLinkWidth(howManyFunctions);
      const linkStartPosition = service.source[0]
        - ((halfFunctions * linkWidth)
          + (halfFunctions * NODES_CONFIG.LINK.margin));
      functions.forEach((functionItem, functionIndex) => {
        const { name: functionName = '', indicators = [], active: functionActive = false } = functionItem;
        const functionNodeXPosition = (functionsCounter * functionMargin) + functionRadius;
        const functionNode = svg.append('g')
          .attr('class', 'node-function cursor-pointer')
          .attr('transform', `translate(${functionNodeXPosition}, ${NODES_CONFIG.FUNCTION.yInitial})`)
          .on('mouseover', function mouseover() {
            showTooltip(this, functionName);
          })
          .on('mouseleave', hideTooltip);

        functionNode.on('click', () => onElementClick(SANKEY_CLICK_TYPE.FUNCTION, {
          data: functionItem,
          serviceIndex,
        }));

        const maxIndicatorLevel = indicators.reduce((count, indicator) => {
          const { level = 0 } = indicator;
          return level > count ? level : count;
        }, 0);
        const functionNodeColor = COLORS[`INDICATOR_LEVEL_${maxIndicatorLevel}`] || COLORS.FUNCTION;
        functionNode.append('circle')
          .attr('cx', functionRadius / 2)
          .attr('r', functionRadius)
          .attr('stroke', functionActive ? COLORS.FUNCTION_STROKE : 'transparent')
          .attr('stroke-width', '2')
          .style('fill', functionNodeColor);

        functionItem.source = [
          functionNodeXPosition + functionRadius / 2,
          NODES_CONFIG.FUNCTION.yInitial + NODES_CONFIG.LINK.gap,
        ];
        functionsCounter += 1;

        const newSource = service.source ? [...service.source] : [];
        newSource[0] = linkStartPosition
          + ((functionIndex * linkWidth)
            + (functionIndex * NODES_CONFIG.LINK.margin));

        const serviceToFunctionLink = makeLink(
          newSource as [number, number],
          functionItem.source as [number, number],
        );

        links.push({
          linkWidth,
          link: serviceToFunctionLink,
          gradient: 'linear-gradient-service-function',
        });

        indicators.forEach((indicator) => {
          const { name: indicatorName = '', level = 0, active: indicatorActive = false } = indicator;
          const indicatorNodeXPosition = (indicatorsCounter * indicatorsMargin) + indicatorRadius;
          const indicatorNode = svg.append('g')
            .attr('class', 'node-indicator cursor-pointer')
            .attr('transform', `translate(${indicatorNodeXPosition}, ${NODES_CONFIG.INDICATOR.yInitial})`)
            .on('mouseover', function mouseover() {
              showTooltip(this, indicatorName);
            })
            .on('mouseleave', hideTooltip);

          indicatorNode.on('click', () => onElementClick(SANKEY_CLICK_TYPE.INDICATOR, {
            data: indicator,
            serviceIndex,
            functionIndex,
          }));

          const indicatorColorString = `INDICATOR_LEVEL_${level}`;
          const indicatorNodeColor = COLORS[indicatorColorString] || COLORS.INDICATOR;
          const hasGradient = indicatorNodeColor === COLORS.INDICATOR;
          indicatorNode.append('circle')
            .attr('cx', indicatorRadius / 2)
            .attr('r', indicatorRadius)
            .attr('stroke', indicatorActive ? COLORS.INDICATOR_STROKE : 'transparent')
            .attr('stroke-width', '2')
            .style('fill', indicatorNodeColor);

          indicator.source = [
            indicatorNodeXPosition + indicatorRadius / 2,
            NODES_CONFIG.INDICATOR.yInitial + NODES_CONFIG.LINK.gap,
          ];
          indicatorsCounter += 1;

          const functionToIndicatorLink = makeLink(
            functionItem.source as [number, number],
            indicator.source as [number, number],
          );

          links.push({
            link: functionToIndicatorLink,
            level,
            color: !hasGradient && indicatorNodeColor,
            gradient: 'linear-gradient-function-indicator',
          });
        });
      });
    });

    /* Links */
    const linksContainer = svg.insert('g', ':first-child').attr('class', 'links');
    if (links.length) {
      /* Order links in order to have the major levels above */
      links.sort((a, b) => {
        if (a.level && b.level) {
          return a.level - b.level;
        }
        if (a.level) {
          return 1;
        }
        if (b.level) {
          return -1;
        }
        return 0;
      }).forEach(({
        link,
        linkWidth,
        gradient,
        color,
      }: any) => {
        linksContainer.append('path')
          .attr('d', link)
          .attr('stroke', color || `url(#${gradient || 'linear-gradient-service-function'})`)
          .attr('fill', 'none')
          .style('stroke-width', linkWidth || NODES_CONFIG.LINK.width);
      });
    }
    /* Make divisions */
    const DIVISION_GAP = 28;
    const divisionsPositions = [
      NODES_CONFIG.SERVICE.height + DIVISION_GAP,
      NODES_CONFIG.FUNCTION.yInitial + DIVISION_GAP,
      NODES_CONFIG.INDICATOR.yInitial + DIVISION_GAP,
    ];
    const divisions = separatorsLabels.map((item, index) => ({
      ...item,
      y: divisionsPositions[index],
    }));
    const divisionsContainer = svg.insert('g', ':first-child').attr('class', 'divisions');
    divisions.forEach(({ title, y }) => {
      const textGroup = divisionsContainer.append('g')
        .attr('transform', `translate(0, ${y})`);
      textGroup.append('text')
        .attr('class', 'text-xs uppercase')
        .attr('text-anchor', 'bottom')
        .text(title)
        .style('transform', 'rotate(-90deg) translate(12px, -22px)');
      textGroup.append('line')
        .attr('x1', -1 * MARGINS.left)
        .attr('x2', WIDTH)
        .style('stroke', COLORS.DIVISION)
        .style('stroke-width', 1)
        .style('stroke-dasharray', '5, 5');
    });
  };

  useEffect(() => {
    if (chartRef.current) {
      drawChart(chartRef.current as HTMLElement);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef]);

  useEffect(() => {
    if (!_isNil(selectedItemIndex) && selectedItemIndex >= 0) {
      const firstService = chartData[0];
      const extraData: IClickExtraData = {
        data: firstService,
      };
      onElementClick(SANKEY_CLICK_TYPE.SERVICE, extraData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="sankey-chart" ref={chartRef} />
  );
};

ChartSankey.propTypes = {
  data: PropTypes.array,
  separatorsLabels: PropTypes.array,
  onClick: PropTypes.func,
};

ChartSankey.defaultProps = {
  data: [],
  separatorsLabels: [],
  onClick: null,
};

export default ChartSankey;
