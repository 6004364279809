/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext } from 'react';

const ModalContext = createContext({
  open: false,
  title: '',
  content: null,
  setOpen: (value: boolean) => {},
  setTitle: (value: string) => {},
  setContent: (content: any) => {},
});

export default ModalContext;
