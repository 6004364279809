import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

interface VimeoProps {
  videoId: string
}
const Vimeo = ({ videoId }: VimeoProps) => (
  <React.Fragment>
    {/* Empty div to avoid hover effect on the iframe */}
    <div className="absolute z-10 h-full w-full" />
    <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
      <iframe
        src={`https://player.vimeo.com/video/${videoId}?badge=0&amp;autopause=0&amp;autoplay=1&amp;player_id=0&amp;app_id=58479`}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        style={{
          position: 'absolute', top: 0, left: 0, width: '100%', height: '100%',
        }}
        title="DG5B_bm-rov_0689-Nodules-seabed.mp4"
      />
    </div>
    <Helmet>
      <script src="https://player.vimeo.com/api/player.js" />
    </Helmet>
  </React.Fragment>
);

Vimeo.propTypes = {
  videoId: PropTypes.string.isRequired,
};

export default Vimeo;
