import _map from 'lodash/map';
import _forEach from 'lodash/forEach';

import { IChartSankeyData, IChartSankeyDataIndicators } from 'interfaces';

/**
 * Look for all the indicators in a service
 * @param service
 */
export const mergeIndicators = (service: IChartSankeyData):IChartSankeyDataIndicators[] => {
  const indicators:IChartSankeyDataIndicators[] = [];
  const { functions } = service;
  _forEach(functions, (functionItem) => {
    _forEach(functionItem.indicators, (indicator) => {
      indicator.parentName = functionItem.name;
      indicators.push({ ...indicator });
    });
  });

  return indicators;
};

/**
 * Active just the indicators with certain name or function name.
 * This function makes a copy of the indicators.
 * @param indicators
 * @param itemName
 * @param isParentName
 */
export const activeIndicators = (
  indicators: IChartSankeyDataIndicators[],
  itemName: string,
  isParentName:boolean = true,
) => _map(indicators, (indicator) => {
  const newIndicator = { ...indicator };
  const { parentName, name } = newIndicator;
  newIndicator.active = isParentName ? parentName === itemName : name === itemName;
  return newIndicator;
});
