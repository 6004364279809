import React, { useState } from 'react';
import _cloneDeep from 'lodash/cloneDeep';
import _isFunction from 'lodash/isFunction';

import { IPropsWithChildren } from 'interfaces';
import Toast, { ToastItem } from 'components/toast/toast';

export const ToastContext = React.createContext({
  items: [] as ToastItem[],
  /* eslint-disable-next-line */
  addToastItem: (item: ToastItem) => {},
  /* eslint-disable-next-line */
  addToastItems: (items: ToastItem[]) => {},
});

const ToastProvider = ({ children }: IPropsWithChildren) => {
  const [items, setItems] = useState<ToastItem[]>([]);
  const itemExist = (item:ToastItem) => items.find(
    (currentItem: ToastItem) => item.id === currentItem.id,
  );

  const addToastItem = (toastItem: ToastItem) => {
    const exists = itemExist(toastItem);
    if (!exists) {
      const newItems = _cloneDeep(items);
      newItems.push(toastItem);
      setItems(newItems);
    }
  };

  const addToastItems = (toastItems: ToastItem[]) => {
    const newItems = _cloneDeep(items);
    const itemsToAdd = toastItems.filter((item: ToastItem) => !itemExist(item));

    if (itemsToAdd.length) {
      itemsToAdd.forEach((item: ToastItem) => newItems.push(item));
      setItems(newItems);
    }
  };

  const onToastClick = (toastItem: ToastItem) => {
    if (_isFunction(toastItem.onClick)) {
      toastItem.onClick(toastItem);
    }
  };

  const onToastLeave = (toastItem: ToastItem) => {
    const newItems = items.map((item: ToastItem) => {
      item.removed = item.id !== toastItem.id;
      return item;
    });
    setItems(newItems);
  };

  const defaultValue = {
    items,
    addToastItem,
    addToastItems,
  };

  return (
    <ToastContext.Provider value={defaultValue}>
      <div className="flex flex-col w-1/4 items-end fixed top-40 right-0 space-y-4 pr-4 z-20">
        {
          items.length
            ? (
              items.map((item: ToastItem) => {
                const {
                  id, title, message, removed,
                } = item;

                if (removed) {
                  return null;
                }

                return (
                  <Toast
                    key={id}
                    id={id}
                    title={title}
                    message={message}
                    onClick={() => onToastClick(item)}
                    onClose={() => onToastLeave(item)}
                  />
                );
              })
            )
            : null
        }
      </div>
      {children}
    </ToastContext.Provider>
  );
};

export default ToastProvider;
