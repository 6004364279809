export const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

enum AMPM {
  AM = 'AM',
  PM = 'PM',
}

const monthsIndexes:{ [key:string]: number } = {};
MONTHS.forEach((month: string, index: number) => {
  monthsIndexes[month] = index;
});

/**
 * Return the month name base on date month index
 * @param index
 */
export const getMonthName = (index: number) => MONTHS[index] || '';

/**
 * Make notification timestamp base on the string date
 * @param stringDate
 * @param year
 */
export const stringToTimestamp = (stringDate: string, year: number = 2024):number => {
  const dateRegexp = /(\w+) ([0-9]+)( at )([0-9]+)[:]([0-9]+)([AM|PM]+)/ig;
  const matches = dateRegexp.exec(stringDate);
  const [, month, dateString, , hours, minutes, ampm] = matches as Array<string>;
  const militaryHour = ampm === AMPM.PM ? hours + 12 : hours;
  const realMonth = monthsIndexes[month];

  const newDate = new Date(
    year,
    realMonth,
    +dateString,
    +militaryHour,
    +minutes,
  );

  return newDate.getTime();
};
