import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import { IPropsWithClassName } from 'interfaces';
import Header from 'components/header/header';
import Footer from 'components/footer/footer';
import { TokenContext } from 'context/TokenProvider';
import Section, { BG_COLOR } from 'components/section/Section';
import SectionHeader from 'components/section/SectionHeader';
import Button from '../components/button/button';

const HEADER_HEIGHT = 142;

interface DefaultLayoutProps extends IPropsWithClassName {
  component: any;
  noMenuOptions: boolean;
  headerMode?: string;
  footerMode?: string;
  needsToken?: boolean;
  maintenance?: boolean;
  rest: any[];
}

const DefaultLayout = ({
  component: Component,
  className,
  headerMode,
  footerMode,
  noMenuOptions = false,
  maintenance = false,
  needsToken = true,
  ...rest
}: DefaultLayoutProps) => {
  const location = useLocation();
  const currentLocationString = location.pathname.replace(/\//ig, ''); // remove slashes in the current path
  const { loading, isValidToken } = React.useContext(TokenContext);
  const componentClasses = classNames(
    'flex',
    'flex-col',
    'min-h-full',
    className,
    {
      [BG_COLOR.GRAY]: !needsToken || (needsToken && !isValidToken),
    },
  );

  useEffect(() => {
    if (window.scrollY > HEADER_HEIGHT) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  });

  const deniedAccessComponent = (
    <Section bgColor={BG_COLOR.GRAY}>
      <SectionHeader title={loading ? 'Validating token...' : ''}>
        {
          !loading
            ? (
              <article className="text-blueGray-900">
                <h2 className="text-5xl font-medium mb-6 ">Access Denied</h2>
                <p>Please request a valid token.</p>
                <p>
                  If you have any questions please contact
                  {' '}
                  <Button type="link" to="mailto:toby@metals.co" title="toby@metals.co" blank />
                </p>
              </article>
            )
            : <React.Fragment />
        }
      </SectionHeader>
    </Section>
  );

  return (
    <div className={componentClasses}>
      <Header className={headerMode} hideLinks={noMenuOptions} />
      <main className={currentLocationString}>
        {!needsToken && <Component {...rest} />}
        {needsToken && isValidToken && <Component {...rest} />}
        {needsToken && !isValidToken && deniedAccessComponent}
      </main>
      {!maintenance && <Footer className={footerMode} hideLinks={noMenuOptions} />}
    </div>
  );
};

DefaultLayout.propTypes = {
  component: PropTypes.any.isRequired,
  className: PropTypes.string,
  headerMode: PropTypes.string,
  footerMode: PropTypes.string,
  needsToken: PropTypes.bool,
  noMenuOptions: PropTypes.bool,
  maintenance: PropTypes.bool,
};

DefaultLayout.defaultProps = {
  className: '',
  headerMode: '',
  footerMode: '',
  needsToken: true,
  noMenuOptions: false,
  maintenance: false,
};

export default DefaultLayout;
