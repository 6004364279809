import { ISankeyChartNodesConfig } from 'interfaces';

export const MARGINS = {
  top: 5,
  right: 0,
  bottom: 50,
  left: 32,
};
export const WIDTH = 1248 - MARGINS.left - MARGINS.right;
export const HEIGHT = 400 - MARGINS.top - MARGINS.bottom;

export const COLORS: { [key:string]:string } = {
  SERVICE: '#CEECF8',
  FUNCTION: '#C5ECC8',
  FUNCTION_STROKE: '#244357',
  INDICATOR: '#28B42E',
  INDICATOR_LINK: '#F4F4F4',
  INDICATOR_STROKE: '#244357',
  INDICATOR_LEVEL_1: '#8AE93F',
  INDICATOR_LEVEL_2: '#FFCC00',
  INDICATOR_LEVEL_3: '#FC5569',
  DIVISION: '#DDDDDD',
};

export const NODES_CONFIG:ISankeyChartNodesConfig = {
  SERVICE: {
    width: 142,
    height: 70,
    radius: 10,
    color: COLORS.SERVICE,
    margin: 35,
    yInitial: 10,
  },
  FUNCTION: {
    radius: 18,
    color: COLORS.FUNCTION,
    yInitial: 215,
  },
  INDICATOR: {
    radius: 12.5,
    color: COLORS.INDICATOR,
    yInitial: 350,
  },
  LINK: {
    gap: 10,
    width: 8,
    margin: 4,
  },
};
