import { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'mapbox-gl';
import MapOperationsContext from 'context/MapOperationsContext';
import { MapLayerProps } from 'interfaces';
import toggleLayer from './utils';
import { SeaMountFill, SeaMountLine } from '../Mapbox';

const SM = ({ map, endpoint, id }: MapLayerProps) => {
  const { showSeaMounts } = useContext(MapOperationsContext);

  useEffect(() => {
    const sourceId = `${id}Source`;
    const fillLayerId = `${id}Fill`;
    const outlineLayerId = `${id}Outline`;

    if (!map.getSource(sourceId)) {
      map.addSource(sourceId, {
        type: 'geojson',
        data: endpoint,
      });

      map.addLayer({
        id: fillLayerId,
        type: 'fill',
        source: sourceId,
        paint: SeaMountFill,
      });

      map.addLayer({
        id: outlineLayerId,
        type: 'line',
        source: sourceId,
        paint: SeaMountLine,
      });
    }

    // Handle show hide this layer base on the current context.
    toggleLayer(map, [fillLayerId, outlineLayerId], showSeaMounts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, showSeaMounts]);

  return null;
};

SM.propTypes = {
  map: PropTypes.instanceOf(Map).isRequired,
  id: PropTypes.string.isRequired,
  endpoint: PropTypes.string.isRequired,
};

export default SM;
