import { IEcosystemServicesData } from 'interfaces';
import { API_SERVER } from '../config';

export enum METHODS {
  INDICATORS = 'indicators',
}

class Api {
  private serverEndpoint: string = API_SERVER;

  constructor(serverEndpoint: string) {
    this.serverEndpoint = serverEndpoint;
  }

  /**
     * Get Ecosystem Service Indicators data
     */
  getIndicators():Promise<IEcosystemServicesData> {
    return fetch(`${this.serverEndpoint}/api/${METHODS.INDICATORS}`)
      .then((response) => response.json());
  }
}

const api = new Api(API_SERVER);

export default api;
